import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom'
import { bindActionCreators } from "redux";

import { PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom'
import { Button, Spin } from 'antd'

// REDUX
import prescribeMedicine from '../../redux/prescribeMedicine'

// MODALS
import CompleteConsultationModal from './components/CompleteConsultationModal';
import { SaveTemplateModal } from './components/SaveTemplateModal';

// COMPONENTS
import { PDMPResource } from './components/PDMPResource'
import { Pharmacy } from './components/Pharmacy'
import { Patient } from './components/Patient'
import { Header } from './components/Header'
import Prescriptions from './components/Prescriptions'
import { CancelPrescriptionsModal } from './components/CancelPrescriptionsModal'

// HOOKS
import { useCaseDetails } from './hooks/useCaseDetails';

// CONTEXT
import { ProviderProvider } from './context/ProviderContext';
import { CaseProvider } from './context/CaseContext';
import { PatientProvider } from './context/PatientContext'
import { DispenseUnitProvider } from './context/DispenseUnitContext'
import { TelemedicineCategoryProvider } from './context/TelemedicineCategoryContext'

// TYPES
import {
    PrescriptionStatusEnum,
    CaseStatusEnum,
    TelemedicineCategoryEnum
 } from '../../types/enums'

// STYLES
import {
    PrescribeMedicineContainer,
    ButtonGroup,
    MedicationGroupContainer
} from "./styles"

const PrescribeMedicine = ({
    provider,
    allPrescriptionsValid,
    pharmacy,
    APMedicineDictionary,
    pdmpViewed,
    actions,
}) => {
    const { id } = useParams()
    const history = useHistory();
    const [rxIndexForTemplate, setRxIndexForTemplate] = useState(null)
    const [showChangePharmacyModal, setShowChangePharmacyModal] = useState(false)
    const [showCancelPrescriptionsModal, setShowCancelPrescriptionsModal] = useState(false)
    const [_case, setCase] = useState(null)

    const { fetchCaseDetails } = useCaseDetails()

    const showButtonGroup = _case && APMedicineDictionary.length
    const isTestosteroneCase = _case?.category.name.toLowerCase() === TelemedicineCategoryEnum.TESTOSTERONE

    const initQueue = async () => {
        const caseDetails = await fetchCaseDetails(id)

        if (caseDetails.prescriptionStatus === PrescriptionStatusEnum.ERRORS) {
            history.push(`/dashboard/physician/caseview/${id}`);
        }

        actions.initPrescriptionQueue(caseDetails)
        setCase(caseDetails)
    }

    const handleCreateTemplate = index => setRxIndexForTemplate(index)

    const handleReviewAndSend = () => {
        const CASE_IS_ALREADY_COMPLETED = _case.status === CaseStatusEnum.CONSULT_COMPLETE
        if (CASE_IS_ALREADY_COMPLETED) setShowCancelPrescriptionsModal(true)
        else actions.setConsultationCompleted(true)
    }

    const handleCancelPreviousPrescriptions = () => {
        setShowCancelPrescriptionsModal(false)
        actions.setConsultationCompleted(true)
    }

    useEffect(() => {
        actions.getAPMedicineDictionary()
        initQueue()
        return () => actions.resetPrescriptionQueue()
    }, [])

    return (
        <ProviderProvider value={{ provider }}>
            <CaseProvider value={{ _case, caseLoading: !!_case }}>
                <PatientProvider value={{
                    patient: {
                        ..._case?.patient,
                        heightFt: _case?.heightFt,
                        heightIn: _case?.heightIn,
                        heightCm: _case && Math.floor((_case.heightFt * 12 + _case.heightIn) * 2.54),
                        weight: _case?.weight,
                        weightKg: Math.floor(_case?.weight * 0.453592)
                    }
                }}>
                    <TelemedicineCategoryProvider>
                        <DispenseUnitProvider>
                            <PrescribeMedicineContainer>
                                <Spin spinning={!_case || !APMedicineDictionary.length}>
                                    <MedicationGroupContainer>
                                        <Header />
                                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                                                <Patient patient={_case?.patient} />
                                                
                                                <Pharmacy
                                                    showChangePharmacyModal={showChangePharmacyModal}
                                                    setShowChangePharmacyModal={setShowChangePharmacyModal}
                                                />

                                                <PDMPResource
                                                    isTestosteroneCase={isTestosteroneCase}
                                                    caseId={_case?._id}
                                                    pdmpResource={_case?.pdmpResource}
                                                    registerPDMPView={() => actions.registerPDMPView(_case?._id, _case?.pdmpResource)}
                                                /> 
                                            </div>
                                            
                                        </div>
                                        <Prescriptions
                                            handleCreateTemplate={handleCreateTemplate}
                                            telemedicineCategoryId={_case?.categoryId}
                                            showChartingTemplate={isTestosteroneCase}
                                            bypassValidation={isTestosteroneCase}
                                        />
                                        {showButtonGroup && (
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>  
                                                {
                                                    isTestosteroneCase && !pdmpViewed && (
                                                        <div style={{ color: 'red' }}>PDMP Not Yet Viewed</div>
                                                    )
                                                }
                                                <ButtonGroup>
                                                    <Button icon={<PlusOutlined />}
                                                        onClick={actions.addPrescriptionToQueue}

                                                    >
                                                        Add Another
                                                    </Button>
                                                    <Button
                                                    
                                                        type="primary"
                                                        disabled={
                                                            !allPrescriptionsValid || 
                                                            !pharmacy ||
                                                            (isTestosteroneCase && !pdmpViewed)
                                                        }
                                                        onClick={handleReviewAndSend}
                                                    >
                                                        Review and Send
                                                    </Button>
                                                </ButtonGroup>
                                            </div>
                                        )}
                                    </MedicationGroupContainer>
                                </Spin>
                                <SaveTemplateModal
                                    rxIndexForTemplate={rxIndexForTemplate}
                                    setRxIndexForTemplate={setRxIndexForTemplate}
                                    _case={_case}
                                />
                                { showCancelPrescriptionsModal && (
                                    <CancelPrescriptionsModal 
                                        hide={() => setShowCancelPrescriptionsModal(false)}
                                        setConsultationCompleted={handleCancelPreviousPrescriptions}
                                    />
                                )}
                                <CompleteConsultationModal requireVisitNote={isTestosteroneCase}/>
                            </PrescribeMedicineContainer>
                        </DispenseUnitProvider>
                    </TelemedicineCategoryProvider>
                </PatientProvider>
            </CaseProvider>
        </ProviderProvider>
    )
}

const mapStateToProps = state => {
    const {
        prescriptions,
        allPrescriptionsValid,
        pharmacy,
        APMedicineDictionary,
        pdmpViewed,
    } = state.prescribeMedicine

    return {
        prescriptions,
        allPrescriptionsValid,
        pharmacy,
        APMedicineDictionary,
        pdmpViewed,
    }
}

const mapDispatchToProps = (dispatch) => {
    const {
        setConsultationCompleted,
        initPrescriptionQueue,
        getAPMedicineDictionary,
        resetPrescriptionQueue,
        addPrescriptionToQueue,
        registerPDMPView,
    } = prescribeMedicine.actions;
    return {
        actions: bindActionCreators(
            {
                setConsultationCompleted,
                initPrescriptionQueue,
                getAPMedicineDictionary,
                resetPrescriptionQueue,
                addPrescriptionToQueue,
                registerPDMPView,
            },
            dispatch
        ),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrescribeMedicine);