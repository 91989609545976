import React, { useEffect, useState, useRef } from 'react'

import {
    Form,
    Input,
    Select,
    Button,
    Typography,
    AutoComplete
} from 'antd'

import { ReloadOutlined } from '@ant-design/icons';
import states from './json/states.json'
import { formatTenDigitPhoneNumber } from '../../../../../../../../utils/phoneFormatter'

import { useGetPharmacySpecialtyTypes } from './hooks/useGetPharmacySpecialtyTypes'

const { Text } = Typography

export const PharmacySearch = ({
    form,
    pharmacySearchResults,
    setPharmacySearchResults,
    handleSelectPharmacy,
}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const searchResultsRef = useRef()

    useEffect(() => {
        setDropdownOpen(pharmacySearchResults.length > 0)
        searchResultsRef.current.focus();
    }, [pharmacySearchResults]);

    const { pharmacySpecialtyTypes } = useGetPharmacySpecialtyTypes()

    const PharmacyOption = ({ pharmacy }) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text style={{ fontWeight: 'bolder' }}>{pharmacy.name}</Text>
                <Text>{formatTenDigitPhoneNumber(pharmacy.phoneOrFax)}</Text>
                <Text>{pharmacy.address1}</Text>
                <Text>{`${pharmacy.city}, ${pharmacy.state} ${pharmacy.zipCode}`}</Text>
                <Text>{pharmacy.primaryPhone}</Text>
                <Text>{pharmacy.specialties?.join(', ')}</Text>
            </div>
        )
    }

    const handleClearForm = () => {
        form.resetFields()
        setPharmacySearchResults([])
    }

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={{ newDefaultPharmacy: false }}
        >
                <Button
                    icon={<ReloadOutlined />}
                    size='small'
                    style={{
                        display: 'block',
                        marginLeft: 'auto',
                        position: 'relative',
                        top: '20px',
                        zIndex: 2
                    }}
                    onClick={handleClearForm}
                >
                    Reset Field
                </Button>
                <Form.Item
                    name='name'
                    label='Pharmacy Name'
                >
                    <AutoComplete
                        style={{ width: '100%' }}
                        onSelect={handleSelectPharmacy}
                        open={dropdownOpen}
                        onBlur={() => setDropdownOpen(false)}
                        onFocus={() => setDropdownOpen(true)}
                        ref={searchResultsRef}
                    >
                        {
                            pharmacySearchResults?.map(pharmacy => <Select.Option key={pharmacy._id} value={pharmacy.name}><PharmacyOption pharmacy={pharmacy} /></Select.Option>)
                        }
                    </AutoComplete>
                </Form.Item>
                <Form.Item
                    name='address'
                    label='Address'
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Form.Item
                        name='city'
                        label="City"
                        style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}

                    >
                        <Input />
                    </Form.Item>
                    <span style={{ display: 'inline-block', width: '24px', lineHeight: '32px', textAlign: 'center' }}></span>
                    <Form.Item
                        name='state'
                        label='State'
                        style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
                    >
                        <Select
                            allowClear
                            showSearch
                            options={Object.keys(states).map(state => ({ value: state, label: state }))}
                        />
                    </Form.Item>
                </Form.Item>
                <Form.Item>
                    <Form.Item
                        name='zip'
                        label="Zip Code"
                        style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}

                    >
                        <Input />
                    </Form.Item>
                    <span style={{ display: 'inline-block', width: '24px', lineHeight: '32px', textAlign: 'center' }}></span>
                    <Form.Item
                        name='phone'
                        label='Phone'
                        style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
                    >
                        <Input />
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    name='specialty'
                    label='Specialty'
                >
                    <Select
                        allowClear
                        options={Object.entries(pharmacySpecialtyTypes).map(([key, value]) => ({ value, label: key }))}
                    />
                </Form.Item>
        </Form>
    )
}