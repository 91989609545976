import React from "react";
import { Drawer } from "antd";

const MainDrawer = ({
  sidebarDrawerStatus,
  changeSidebarDrawerState,
  children,
}) => {
  const onDrawerClose = () => {
    changeSidebarDrawerState();
  };
  return (
    <Drawer
      className="main-drawer"
      visible={sidebarDrawerStatus}
      closable
      maskClosable
      width="235px"
      placement="left"
      onClose={onDrawerClose}
      getContainer={false}
    >
      {children}
    </Drawer>
  );
};

export default MainDrawer;
