import React, { useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import apihelper from "../../../../axios/apiHelper";
import { history } from "../../../../helpers/history";
import { useLocation } from "react-router-dom";

const LoginCase = ({ caseId }) => {
    const onFinishCase = (values) => {
        apihelper
            .LoginCase(values)
            .then((res) => {
                if (res.data.status === true) {
                    message.success("successfully logged in");

                    const { data } = res;
                    const { payload } = data;

                    window.localStorage.setItem("caseId", payload.caseId);
                    window.localStorage.setItem("token", payload.token);
                    window.localStorage.setItem("status", payload.status);
                    window.localStorage.setItem("pstatus", "dashboard-not");

                    if (payload.consultationType) {
                        window.localStorage.setItem("consultationType", payload.consultationType);
                    }

                    if (
                        payload.status === "pending audio consult" ||
                        payload.status === "pending video consult" ||
                        payload.status === "waiting room"
                    ) {
                        history.push(`/case/scheduled-call/${payload.caseId}`);
                    } else {
                        // TODO push to "done" page
                        // history.push(`/case/prescription/${payload.caseId}`);
                        history.push(`/case/scheduled-call/${payload.caseId}`);
                    }
                } else {
                    message.error("Unable to login. Please make sure that your are using a valid case Id");
                }
            }).catch((error) => {
                message.error("Login failed. Please make sure that your are using a valid case Id");
            });
    };

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const [form] = Form.useForm();

    let query = useQuery()
    var id = query.get("case-id");

    useEffect(() => {
        if (id) { form.setFieldsValue({ caseId: id }); }
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <div className="login-selection-part-login-signup">
                <p style={{ marginBottom: "20px", color: "#171C2F" }}>
                    <b>Login</b>
                </p>
            </div>

            <Form
                form={form}
                name="basic"
                layout="vertical"
                size="large"
                onFinish={onFinishCase}
            >
                <Form.Item
                    name="caseId"
                    rules={[{ required: true, message: "Please input appointment Id" }]}
                >
                    { caseId ?
                        <Input placeholder="Appointment ID" value={caseId}/>
                        : <Input placeholder="Appointment ID" />
                    }
                </Form.Item>
                <Form.Item
                    name="token"
                    rules={[{ required: true, message: "Please check the Captcha" }]}
                >
                    <ReCAPTCHA
                        sitekey="6Lc0yf8UAAAAAFz_dEpXPs4rjI5DeiChF4dtKCGN"
                        style={{ width: "45% !important" }}
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                        Login
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default LoginCase;
