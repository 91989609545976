/* eslint-disable react-hooks/exhaustive-deps */
//component to register case for patient

import React, { useState, useEffect } from "react";
import { useParams, } from "react-router-dom";
import { Layout, message } from "antd";

// import components
import ScheduledCall from "./ScheduledCall";
import PatientApi from "../../../axios/patient/PatientApi";

const { Content } = Layout;

const ScheduledConsultation = () => {
  const [loading, setLoading] = useState(false);
  const { id: caseId } = useParams();


  const [appointments, setAppointments] = useState({});


  const fetchPatientAppointments = () => {
    setLoading(true);

    PatientApi.fetchAppointments(caseId)
      .then(res => {
        if (res.data.error === false) {
          const data = res.data.caseDto;
          setAppointments(data);
        } else {
          message.error("Unable to fetch Case Details. Please come back after a while!");
        }
      }).catch(err => {
        message.error("Error occurred while fetching patient appointment(s)");
      }).finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchPatientAppointments();
  }, []);

  return (
    <Content>
      <ScheduledCall
        caseDetails={appointments}
        fetchCaseDetails={fetchPatientAppointments}
        scheduleDetailsLoading={loading}
      />
    </Content>
  );
};

export default ScheduledConsultation;
