/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  Radio,
  Typography,
  Modal,
  Form,
  Select,
  Spin,
  message,
  Checkbox,
} from "antd";

import {
  LoadingOutlined,
  StarFilled,
} from "@ant-design/icons";
import { AntTinyWrapper } from "../../components/AntTinyWrapper";

import incompleteCases from "../../redux/incompleteCases"

import decodeToken from "../../utils/decodeToken";
import PhysicianAPI from "../../axios/physician/PhysicianApi";
import Handlebars from "handlebars";
import { CaseStatusEnum } from '../../types/enums'

const { Option } = Select;
const { Text } = Typography;

const CaseUpdateStatus = ({
  modalVisibility,
  actionCaseStatus,
  setModalVisibility,
  caseCategory,
  caseId,
  fetchCaseDetails,
  actions,
  physicianDetails,
  physicianNeedsReview
}) => {
  const [form] = Form.useForm();
  const [selectedTemplateMessage, setSelectedTemplateMessage] = useState("");
  const [commonTemplateMessages, setCommonTemplateMessages] = useState([]);
  const [modalload, setModalLoad] = useState(true);
  const [disableMessage, setDisableMessage] = useState(true);
  const [patientExplanation, setPatientExplanation] = useState(null);
  const [requireInformationStatus, setRequireInformationStatus] = useState();
  const [caseDetails, setCaseDetails] = useState({});
  const [sendInformationCheckBox, setSendInformationCheckBox] = useState(actionCaseStatus !== CaseStatusEnum.REFER);
  const [templateFilter, setTemplateFilter] = useState("My");
  const [rejectionReason, setRejectionReason] = useState(null)

  const user = useMemo(() => {
    const decodedToken = decodeToken(localStorage.getItem('token'));
    return decodedToken?.user;
  }, []);

  const sortFavorites = (templates) => {
    return [...templates].sort(a => a.favoritedBy ? -1 : 1);
  }

  const compileHandlebars = (message) => {
    const handlebarsTemplate = Handlebars.compile(message);

    return handlebarsTemplate({
      case: caseDetails,
      physician: physicianDetails,
      patient: caseDetails.patient
    })
  }

  const requiresInformation = [
    {
      _id: "5fc088f0dc6ef1ea454e2df2",
      name: "invalid face photo",
      displayName: CaseStatusEnum.INVALID_FACE_PHOTO,
    },
    {
      _id: "5fc08922dc6ef1ea454e2df3",
      name: "invalid photo id",
      displayName: CaseStatusEnum.INVALID_PHOTO_ID,
    },
    {
      _id: "5eeb23518a1eef7389dc1e84",
      name: "additional info required",
      displayName: CaseStatusEnum.ADDITIONAL_INFO_REQUIRED,
    },
  ];

  useEffect(() => {
    setDisableMessage(true);
    if (modalVisibility && actionCaseStatus !== CaseStatusEnum.ADDITIONAL_INFO_REQUIRED) {
      getTemplateMessages(actionCaseStatus, caseCategory);
      setDisableMessage(false);
    }

    if (modalVisibility && actionCaseStatus === CaseStatusEnum.ADDITIONAL_INFO_REQUIRED) {
      setModalLoad(false);
      if (requireInformationStatus) {
        getTemplateMessages(requireInformationStatus, caseCategory);
        setDisableMessage(false);
      }
    }
  }, [
    modalVisibility,
    actionCaseStatus,
    caseCategory,
    requireInformationStatus,
  ]);

  async function fetchCase() {
    PhysicianAPI.getCaseDetailsById(caseId)
        .then(res => setCaseDetails(res.data.payload))
        .catch(() => message.error('Unable to fetch case data'));
  }

  useEffect(() => {
    if (caseId) fetchCase()
  }, [caseId]);

  function getTemplateMessages(status, category) {
    const filters = {
      categoryIds: [category],
      status: status,
    };
    setModalLoad(true);
    PhysicianAPI.getTemplates('case', { filters })
      .then((response) => {
        setCommonTemplateMessages(sortFavorites(response.data.templates));
        setModalLoad(false);
      })
      .catch((error) => {
        setModalLoad(false);
        console.log(
          "===================== template message fetch error ====================================",
          error
        );
      });
  }

  const handlePatientExplanationChange = (value) => {
    setPatientExplanation(value);
    if (value !== selectedTemplateMessage) {
      setSelectedTemplateMessage("");
    }
  };

  const handleRequireInformationChange = (value) => {
    setRequireInformationStatus(value);
  };

  const handleTemplateSelectionChange = (value) => {
    setSelectedTemplateMessage(value);
    setPatientExplanation(value);
    form.setFieldsValue({ "Patient Explanation": value });
  };

  const handleCancel = () => {
    setModalVisibility(false);
    setPatientExplanation("");
    setSelectedTemplateMessage("");
    setRequireInformationStatus();
    form.resetFields();
  };

  const handleStatusUpdate = () => {
    let statusData = {
      patientExplanation,
      sendToPatient: sendInformationCheckBox,
    };

    if (actionCaseStatus === CaseStatusEnum.ADDITIONAL_INFO_REQUIRED) {
      statusData.status = requireInformationStatus
    } else {
      statusData.status = actionCaseStatus
      actionCaseStatus === CaseStatusEnum.REFER && (statusData.rejectionReason = rejectionReason)
    }

    setModalLoad(true);

    PhysicianAPI.updateCaseStatus(caseId, statusData)
      .then(({ data }) => {
        if (!data || !data.success) throw new Error(data?.message || null)
        setModalVisibility(false)
        message.success("Successfully updated case status");
        fetchCaseDetails();
        actions.getIncompleteCases()
        fetchCase(caseId)
      })
      .catch((error) => {
        message.error(error.response?.data?.message || "Unable to update case status. Please try again later!");
      })
      .finally(() => setModalLoad(false));
  };

  return (
    <Modal
      visible={modalVisibility}
      destroyOnClose={true}
      onOk={handleStatusUpdate}
      onCancel={handleCancel}
      width='35vw'
      okButtonProps={{
        disabled: 
          (actionCaseStatus === CaseStatusEnum.REFER && !rejectionReason) 
          || !patientExplanation
      }}
      okText={
        physicianNeedsReview && actionCaseStatus === CaseStatusEnum.REFER
          ? "Refer"
          : "Update Status"
      }
    >
      <Spin spinning={modalload} indicator={<LoadingOutlined />}>
        <Form form={form} name="update case status modal">
          {actionCaseStatus !== CaseStatusEnum.ADDITIONAL_INFO_REQUIRED ? (
            <Form.Item
              label="Selected Case Status"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Text
                style={{ textTransform: "capitalize", fontWeight: "bolder" }}
              >
                {actionCaseStatus}
              </Text>
            </Form.Item>
          ) : (
            <Form.Item
              label="Case Status"
              name="case status"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              required={true}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                size="large"
                style={{ textTransform: "capitalize" }}
                value={requireInformationStatus && requireInformationStatus}
                placeholder="Select Case Status"
                onChange={handleRequireInformationChange}
              >
                {requiresInformation &&
                  requiresInformation.map((eachStatus) => (
                    <Option
                      key={eachStatus._id}
                      value={eachStatus.name}
                      style={{ textTransform: "capitalize" }}
                    >
                      {eachStatus.displayName}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            label="Select a template message as patient explanation"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Radio.Group
              value={templateFilter}
              onChange={(e) => {
                setSelectedTemplateMessage("");
                setTemplateFilter(e.target.value);
              }}
            >
              <Radio.Button value="All">All</Radio.Button>
              <Radio.Button value="My">My</Radio.Button>
              <Radio.Button value="Shared">Shared</Radio.Button>
              <Radio.Button value="Admin">Admin</Radio.Button>
            </Radio.Group>
            <Select
              size="large"
              placeholder="Add template message as expanation to patient"
              onChange={handleTemplateSelectionChange}
              showSearch
              filterOption={(input, option) => {
                return (
                  option.key.toLowerCase().includes(input.toLowerCase()) ||
                  option.value.toLowerCase().includes(input.toLowerCase())
                );
              }}
              value={selectedTemplateMessage}
              disabled={disableMessage}
            >
              {commonTemplateMessages
                .filter((template) => {
                  switch (templateFilter) {
                    case "Shared":
                      return (
                        !!template.ownerId &&
                        template.ownerId?._id !== user?._id
                      );
                    case "Admin":
                      return !template.ownerId;
                    case "My":
                      return template.ownerId?._id === user?._id;
                    default:
                      return true;
                  }
                })
                .map((eachTemplateMessage, index) => (
                  <Option
                    key={`${eachTemplateMessage.title}-${index}`}
                    value={compileHandlebars(eachTemplateMessage.message)}
                  >
                    {eachTemplateMessage.favoritedBy && (
                      <StarFilled style={{ color: "#7BAD7E" }} />
                    )}
                    {eachTemplateMessage.title}
                  </Option>
                ))}
              <Option key="custom-message" value={""}>
                Custom Message
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="Patient Explanation"
            label={<span style={{ color: '#7bad7b', fontWeight: 'bold' }}>Explanation To Patient</span>}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Form.Item>
              <Checkbox
                checked={sendInformationCheckBox}
                onChange={(event) => setSendInformationCheckBox(event.target.checked)}
              >
                Send explanation to patient
              </Checkbox>
            </Form.Item> 
            <AntTinyWrapper
              value={patientExplanation}
              onChange={handlePatientExplanationChange}
              disabled={disableMessage}
            />
          </Form.Item>
          {
            actionCaseStatus === CaseStatusEnum.REFER && (
              <Form.Item
                name="Rejection Reason"
                label={<span style={{ color: "#7bad7e", fontWeight: 'bold' }}>Rejection Reason (to be provided to reviewing physician)</span>}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: true }]}
              >
                <AntTinyWrapper
                  value={rejectionReason}
                  onChange={setRejectionReason}
                  disabled={disableMessage}
                />
              </Form.Item>
            )
          }
        </Form>
      </Spin>
    </Modal>
  );
};


const mapDispatchToProps = (dispatch) => {
  const { getIncompleteCases } = incompleteCases.actions

  return {
    actions: bindActionCreators(
      {
        getIncompleteCases,
      },
      dispatch
    ),
  };
};

export default connect(null, mapDispatchToProps)(CaseUpdateStatus)