import React, { useState, useEffect } from "react";
import { Row, Col, List, Button, Typography, Spin, message, Modal } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  FormOutlined,
  LoadingOutlined,
  ExclamationCircleOutlined,
  CheckOutlined
} from "@ant-design/icons";
import "./CasePhysicianNotes.css";
import AddPhysicianNoteComponent from "../AddPhysicianNoteComponent/AddPhysicianNoteComponent";
import EditPhysicianNoteComponent from "../EditPhysicianNoteComponent/EditPhysicianNoteComponent";
import PhysicianApi from "../../../../axios/physician/PhysicianApi";
import { dateTimeFormatter } from "../../../../utils/dateFormatter";
import HTMLReactParser from "html-react-parser";

const OptionsButton = ({ icon, text, note, handleClick }) => (
  <Button icon={React.createElement(icon)} onClick={() => handleClick(note)}>
    {text}
  </Button>
);

const { Title, Text } = Typography;

const CasePhysicianNotes = ({ caseId, caseDetails, fetchCaseDetails, clearUnreadNotesCount, physicianDetails }) => {
  const [physicianNotes, setPhysicianNotes] = useState([]);
  const [notesLoading, setNotesLoading] = useState(false);
  const [noteAddLoading, setNoteAddLoading] = useState(false);
  const [noteEditLoading, setNoteEditLoading] = useState(false);
  const [addNoteModalVisible, setAddNoteModalVisible] = useState(false);
  const [editNoteModalVisible, setEditNoteModalVisible] = useState(false);
  const [noteToEdit, setNoteToEdit] = useState({});

  const getAllPhysicianNoteRelatedToCase = (caseId) => {
    setNotesLoading(true);
    PhysicianApi.getNotes(caseId)
      .then((response) => {
        const { data } = response;
        if (data.success) {
          const { payload } = data;
          if (payload && Array.isArray(payload)) {
            const notesList = payload.map((eachItem) => ({
              ...eachItem,
              createdDate: eachItem.createdDate
                ? dateTimeFormatter(eachItem.createdDate)
                : "NA",
              updatedDate: eachItem.updatedDate
                ? dateTimeFormatter(eachItem.updatedDate)
                : "NA",
            }));
            setPhysicianNotes(notesList);
          }
          message.success("Successfully fetched the physician notes");
        } else {
          if (data && data.message) {
            message.warn(data.message);
          } else {
            message.warn("Unable to fetch Physician notes. Please try again!");
          }
        }
        setNotesLoading(false);
      })
      .then(() => clearUnreadNotesCount(caseId))
      .catch((error) => {
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error("Unable to delete state. Please try again!");
          }
        } else {
          message.error("Unable to delete state. Please try again!");
        }
        setNotesLoading(false);
      });
  };

  const addPhysicianNote = (caseId, noteDetails) => {
    setNoteAddLoading(true);
    const { title, note } = noteDetails;
    const reqData = {
      title,
      content: note,
    };
    PhysicianApi.addPhysicianNote(caseId, reqData)
      .then((response) => {
        const { data } = response;
        if (data.success) {
          message.success("Successfully added physician note");
          setAddNoteModalVisible(false);
          getAllPhysicianNoteRelatedToCase(caseId);
        } else {
          if (data && data.message) {
            message.warn(data.message);
          } else {
            message.warn("Unable to add note. Please try again!");
          }
        }
        setNoteAddLoading(false);
      })
      .catch((error) => {
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error("Unable to add note. Please try again!");
          }
        } else {
          message.error("Unable to add note. Please try again!");
        }
        setNoteAddLoading(false);
      });
  };

  const editPhysicianNote = (noteId, noteDetails) => {
    setNoteEditLoading(true);    
    return PhysicianApi.updatePhysicianNote(noteId, noteDetails)
      .then((response) => {
        const { data } = response;
        if (data.success) {
          message.success("Successfully updated physician note");
          setEditNoteModalVisible(false);
          getAllPhysicianNoteRelatedToCase(caseId);
        } else {
          if (data && data.message) {
            message.warn(data.message);
          } else {
            message.warn("Unable to update note. Please try again!");
          }
        }
        setNoteEditLoading(false);
      })
      .catch((error) => {
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error("Unable to update note. Please try again!");
          }
        } else {
          message.error("Unable to update note. Please try again!");
        }
        setNoteEditLoading(false);
      });
  };

  const deleteNote = (noteDetails) => {
    setNotesLoading(true);
    const { _id: noteId } = noteDetails;

    PhysicianApi.deleteNote(noteId)
      .then((response) => {
        const { data } = response;
        setNotesLoading(false);
        if (data.success) {
          message.success(`Successfully deleted ${noteDetails.title} note`);
          getAllPhysicianNoteRelatedToCase(caseId);
        } else {
          if (data && data.message) {
            message.warn(data.message);
          } else {
            message.warn(
              `Unable to delete ${noteDetails.title} note. Please try again!`
            );
          }
        }
      })
      .catch((error) => {
        setNotesLoading(false);
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error("Unable to delete note. Please try again!");
          }
        } else {
          message.error("Unable to delete note. Please try again!");
        }
      });
  };

  const deletePhysicianNote = (selectedNote) => {
    Modal.confirm({
      title: "Please confirmation before deleting note",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure about deleting the ${selectedNote.title} note?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => deleteNote(selectedNote),
      onCancel: () => {
        return false;
      },
    });
  };

  const initialNetworkRequest = () => {
    if (caseId) {
      getAllPhysicianNoteRelatedToCase(caseId);
    }
  };

  const onAddNoteSubmit = (value) => {
    addPhysicianNote(caseId, value);
  };

  const onAddNoteCancel = () => {
    setAddNoteModalVisible(false);
  };

  const onEditNoteSubmit = (value) => {
    editPhysicianNote(noteToEdit._id, value);
  };

  const onEditNoteCancel = () => {
    setEditNoteModalVisible(false);
    setNoteToEdit({});
  };

  const handleAddNoteClick = () => {
    setAddNoteModalVisible(true);
  };

  const handleEditClick = (selectedNote) => {
    setNoteToEdit(selectedNote);
    setEditNoteModalVisible(true);
  };

  const handleDeleteClick = (selectedNote) => {
    deletePhysicianNote(selectedNote);
  };

  const handleConfirm = async (selectedNote) => {
    await editPhysicianNote(selectedNote._id, { confirmed: true })
    fetchCaseDetails(caseId)
  }

  // eslint-disable-next-line
  useEffect(initialNetworkRequest, [caseDetails]);
  
  return (
    <Spin spinning={notesLoading} indicator={<LoadingOutlined />}>
      <article>
        <List
          itemLayout="vertical"
          size="large"
          dataSource={physicianNotes}
          header={
            <Row>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Title className="title-color" level={4}>
                  Physician Notes Related to Case
                </Title>
              </Col>
              <Col
                className="right-align-content"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
              >
                <Button
                  type="primary"
                  icon={<FormOutlined />}
                  onClick={handleAddNoteClick}
                >
                  Add Note
                </Button>
              </Col>
            </Row>
          }
          renderItem={(item) => {
            const noteIsFromUser = physicianDetails.id === item.authorId
            const needsConfirmation = item.needsConfirmationBy?.includes(physicianDetails.userId) && !item.confirmedBy.includes(physicianDetails.userId)
            
            const actions = noteIsFromUser 
              ? [
                <OptionsButton
                  icon={EditOutlined}
                  text="Edit"
                  key="edit-button"
                  note={item}
                  handleClick={handleEditClick}
                />,
                <OptionsButton
                  icon={DeleteOutlined}
                  text="Delete"
                  key="delete-button"
                  note={item}
                  handleClick={handleDeleteClick}
                />,
              ]:
              [
                needsConfirmation && (
                  <OptionsButton
                    icon={CheckOutlined}
                    text="OK"
                    key="confirm-button"
                    note={item}
                    handleClick={() => handleConfirm(item)}
                  />
                )
              ]

            return (<List.Item
              key={item._id}
              actions={actions}
            >
              <List.Item.Meta
                title={<Text strong>{item.title}</Text>}
                description={
                  <>
                    {item.rxRequest && (
                      <div className='title-color' style={{ marginTop: -10, marginBottom: 10 }}>
                        associated with {item.rxRequest.requestType} request from {dateTimeFormatter(item.rxRequest.createdAt)}
                      </div>
                    )}
                    Created by:{" "}
                    <span className="title-color">{item.authorName}</span>,
                    Created Date:{" "}
                    <span className="title-color">{item.createdDate}</span>,
                    Last Updated Date:{" "}
                    <span className="title-color">{item.updatedDate}</span>
                  </>
                }
              />
              <div>{HTMLReactParser(item.content)}</div>
            </List.Item>
        )}}
        />
        <AddPhysicianNoteComponent
          addNoteModalVisible={addNoteModalVisible}
          noteAddLoading={noteAddLoading}
          handleAddNoteSubmit={onAddNoteSubmit}
          handleAddNoteCancel={onAddNoteCancel}
        />
        {noteToEdit && editNoteModalVisible ? (
          <EditPhysicianNoteComponent
            noteEditLoading={noteEditLoading}
            editingNote={noteToEdit}
            editNoteModalVisible={editNoteModalVisible}
            handleEditNoteSubmit={onEditNoteSubmit}
            handleEditNoteCancel={onEditNoteCancel}
          />
        ) : (
          <></>
        )}
      </article>
    </Spin>
  );
};

export default CasePhysicianNotes;
