import { normalizeStrengthString } from '../utils/prescribeMedicationUtils'
import { telemedicineCategoryConstants } from '../../../../../constants'

export const required = { required: true, message: 'Required' }
const ignoredCategories = [telemedicineCategoryConstants.WEIGHT_MANAGEMENT]

export const isMismatch = (rxComparisonMedications, requestedMedication, telemedicineCategoryId) => ({

    validator: ({ field }, value) => {
        if (ignoredCategories.map(category => category.categoryId).includes(telemedicineCategoryId)) return Promise.resolve()
        if (!value) return Promise.reject()
        if (!requestedMedication) return Promise.resolve()

        const APLookupMatches = rxComparisonMedications?.filter(({ name, drug_name }) => {
            return (
                name.toLowerCase().includes(requestedMedication.drugName.toLowerCase()) ||
                requestedMedication?.drugName.toLowerCase().toLowerCase().includes(name.toLowerCase()) ||
                drug_name.toLowerCase().includes(requestedMedication.drugName.toLowerCase()) ||
                requestedMedication.drugName.toLowerCase().includes(drug_name.toLowerCase())
            )
        })
        
        const shouldValiate = APLookupMatches.length > 0

        if (!shouldValiate) return Promise.resolve()

        const adaptedRequestedMedication = {
            ...requestedMedication, 
            name: requestedMedication.drugName,
            strength: requestedMedication.dosage?.replace(" ", ""), 
            quantity: requestedMedication.pillCount
        }

        if (adaptedRequestedMedication.strength.toLowerCase() === '1000mg') {
            adaptedRequestedMedication.strength = '1gm'
        }

        if (field === 'name') {
            const drugMatch = APLookupMatches?.find(({ drug_name }) => {
                const drugMatchKeyWords = drug_name.toLowerCase().split(/\sand\s|\s+/);
                return drugMatchKeyWords.filter(word => {
                    return (
                        value.toLowerCase().includes(word.toLowerCase()) ||
                        word.toLowerCase().includes(value.toLowerCase())
                    )
                }).length > 0
            })

            if (drugMatch?.ndc === requestedMedication.ndc) return Promise.resolve()
            return !drugMatch ? Promise.reject(new Error("Mismatch")) : Promise.resolve()
        }

        if (value && field.toLowerCase() === 'strength') {
            value = normalizeStrengthString(requestedMedication.dosage, value?.toString())
            if (value.toLowerCase() === 'compound') return Promise.resolve()
        }
        
        const isMismatch = value && value.toString() !== adaptedRequestedMedication[field]?.toString()
        return isMismatch ? Promise.reject(new Error("Mismatch")) : Promise.resolve()
    },
    warningOnly: true
})

export const isPositive = { validator: (_, value) => value >= 0 ? Promise.resolve() : Promise.reject(new Error('Quantity must be greater than or equal to 0')) }

export const isGreaterThanZero = { validator: (_, value) => value > 0 ? Promise.resolve() : Promise.reject(new Error('Quantity must be greater than 0')) }

export const isControlledSubstance = (selectedStrength, provider) => ({
    validator() {
        return Promise.resolve()
    }
})