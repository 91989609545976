import openSocket from "socket.io-client";
import config from "../config";

const { webSocket } = config;

const socketTwo = openSocket(webSocket.baseUrltwo);

export default socketTwo;

export function socketTwo_init() {
  socketTwo.on("connect", () => {
    console.log(
      "==================== connected to socket Two ================================="
    );
  });
}

export function socketTwo_close() {
  if (socketTwo && typeof socketTwo.close === "function") {
    socketTwo.close();
  }
}

