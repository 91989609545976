import React, { useState, useMemo } from "react";
import { Table, Button, Modal, Select, Input } from "antd";
import TemplateForm from './TemplateForm';
import {
  EditOutlined,
  EyeOutlined,
  StarOutlined,
  StarFilled,
  LockOutlined,
  LockFilled
} from "@ant-design/icons";
import PhysicianAPI from '../../axios/physician/PhysicianApi';
import { StyledFiltersSection, Label, StyledSpin } from './styled';


const TemplatesTable = ({ 
  type, 
  user,
  templates, 
  templateFilters, 
  telemedicineCategories,
  caseStatuses,
  setTemplateFilters,
  handleFinish,
  searchTemplates,
  loading 
}) => {
  const [templateFormIsOpen, setTemplateFormIsOpen] = useState(false);
  const [templateFormMode, setTemplateFormMode] = useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);

  const selectedTemplate = useMemo(() => {
    return templates.find(template => template._id === selectedTemplateId) || {}; 
  }, [templates, selectedTemplateId])

  const _handleFinish = () => {
    setTemplateFormIsOpen(false)
    handleFinish()
    searchTemplates({ type })
  }

  const openTemplateForm = (mode, template) => {
    setTemplateFormMode(mode);
    setTemplateFormIsOpen(true);
    setSelectedTemplateId(template._id);
  }

  const handleUpdate = async fn => {
    await fn()
    _handleFinish()
    searchTemplates({ type })
  }

  return (
    <StyledSpin spinning={loading}>
      <StyledFiltersSection>

      <Label label='Owner:'>
        <Select style={{ width: '220px'}} value={templateFilters.owner} onChange={(value) => setTemplateFilters(filters => ({ ...filters, owner: value }))} dropdownMatchSelectWidth={false}>
          <Select.Option value={null} label='All'>All</Select.Option>
          <Select.Option value='Admin'>Admin Templates</Select.Option>
          {/* <Select.Option value='Shared'>Shared Templates</Select.Option>  NOT BEING USED ON THE BACK END*/}
          <Select.Option value={user?._id}>My Templates</Select.Option>
        </Select>
            </Label>

        {type === 'case' && (
          <>
            <Label label='Telemedicine Category:'>
              <Select style={{ width: '220px'}} value={templateFilters.telemedicineCategory} onChange={(value) => setTemplateFilters(filters => ({ ...filters, telemedicineCategory: value }))} dropdownMatchSelectWidth={false}>
                <Select.Option value='All'>All</Select.Option>
                {telemedicineCategories.map(category => <Select.Option key={category._id} value={category._id}>{category.name}</Select.Option>)}
              </Select>
            </Label>

            <Label label='Case Status:'>
              <Select style={{ width: '220px'}} value={templateFilters.caseStatus} onChange={(value) => setTemplateFilters(filters => ({ ...filters, caseStatus: value }))} dropdownMatchSelectWidth={false}>
                <Select.Option value='All'>All</Select.Option>
                {caseStatuses.map(status => <Select.Option key={status._id} value={status._id}>{status.name}</Select.Option>)}
              </Select>
            </Label>
          </>
        )}

        <Label label='Search:'>
          <Input style={{ width: '220px'}} value={templateFilters.searchValue} onChange={(event) => {
            const { value } = event.target;
            setTemplateFilters(filters => ({ ...filters, searchValue: value }))
          }} />
        </Label>
      </StyledFiltersSection>
      <Table
        columns={[
          {
            title: "Title",
            dataIndex: "title",
            key: "title",
            width: '20%',
            onFilter: (filters, record) => {
              let ownerFilter;

              switch (filters.owner) {
                case 'Shared':
                  ownerFilter = !!record.ownerId && record.ownerId !== user?._id;
                  break;
                case 'Admin':
                  ownerFilter = !record.ownerId;
                  break;
                case user?._id:
                  ownerFilter = String(record.ownerId) === filters.owner;
                  break;
                default:
                  ownerFilter = true;
              }

              const telemedicineCategoryFilter = filters.telemedicineCategory && filters.telemedicineCategory !== 'All'
                  ? record.teleMedicineCategoryIds?.find(category => category._id === filters.telemedicineCategory)
                  : true

              const caseStatusFilter = filters.caseStatus && filters.caseStatus !== 'All'
                  ? String(record.statusId?._id) === filters.caseStatus
                  : true

              const searchValueFilter = !filters.searchValue || (record.title?.toLowerCase().includes(filters.searchValue?.toLowerCase()) || record.message?.toLowerCase().includes(filters.searchValue?.toLowerCase()))

              return ownerFilter && telemedicineCategoryFilter && caseStatusFilter && searchValueFilter;
            },
            filteredValue: [templateFilters]
          },
          ...(
            type === 'case'
              ? [{
                title: "Telemedicine Category",
                dataIndex: "teleMedicineCategories",
                key: "telemedicine-category",
                width: '20%',
                render: vals => (
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center', 
                    maxHeight: '100px', 
                    overflow: 'scroll'
                  }}>
                    <ul>
                      { vals?.split(',').map(val => <li key={val}>{val}</li>)}
                    </ul>
                  </div>
                )
              },
              {
                title: "Assigned Case Status",
                dataIndex: "caseStatus",
                key: "case-status",
                width: '20%'
              }]
              : []
          ),
          {
            title: 'Actions',
            align: "right",
            render: (template) => (
              <>
                <Button
                  shape="circle"
                  icon={<EyeOutlined />}
                  onClick={() => openTemplateForm('View', template)}
                  style={{ marginRight: 15 }}
                />
                {template.ownerId === user?._id && <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => openTemplateForm('Edit', template)}
                  style={{ marginRight: 15 }}
                />
                }
                <Button
                  shape='circle'
                  icon={template.isFavorite ? <StarFilled style={{ color: '#7BAD7E' }} /> : <StarOutlined style={{ color: '#7BAD7E' }} />}
                  onClick={() => handleUpdate(() => PhysicianAPI.toggleFavoriteTemplate(template._id))}
                />
                {template.ownerId === user?._id && <Button
                  shape='circle'
                  icon={template.isPrivate ? <LockFilled style={{ color: '#edac1f' }} /> : <LockOutlined style={{ color: '#edac1f' }} />}
                  onClick={() => handleUpdate(() => PhysicianAPI.togglePrivateTemplate(template._id))}
                  style={{ marginLeft: 15 }}
                />
                }
              </>
            ),
          }
        ]}
        dataSource={templates}
        pagination={false}
        scroll={{ y: '70vh', x: true }}
      />
      <Modal
        maskClosable
        centered
        closable={false}
        footer={null}
        visible={templateFormIsOpen}
        width='50vw'
        onCancel={() => setTemplateFormIsOpen(false)}
        destroyOnClose
      >
        <TemplateForm
          mode={templateFormMode}
          type={type}
          template={selectedTemplate}
          isOwner={selectedTemplate.ownerId === user?._id}
          handleFinish={_handleFinish}
        />
      </Modal>
    </StyledSpin>
  );
}

export default TemplatesTable;