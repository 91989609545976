import React from "react";
import { Menu, Dropdown, Button } from "antd";
import { withRouter } from "react-router";
import { socket_close } from "../axios/socket";

const UserOptions = ({ children, history }) => {
  const handleLogout = (e) => {
    e.preventDefault();
    let loginUrl = "/login-case";
    const token = localStorage.getItem("clientToken");
    if (token) {
      loginUrl += `?token=${token}`;
    }
    socket_close();
    localStorage.clear();
    history.push(loginUrl);
  };

  const menu = (
    <Menu className="layout-header-user-option-menu">
      <Menu.Item
        className="layout-header-user-option-menu-dropdown"
        key="logout"
      >
        <Button type="link" onClick={handleLogout}>
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      placement="bottomRight"
      overlayStyle={{ width: "230px" }}
    >
      <Button type="link">{children}</Button>
    </Dropdown>
  );
};

export default withRouter(UserOptions);
