import openSocket from 'socket.io-client';
import config from "../config";

const { webSocket } = config;

const socket = openSocket(webSocket.baseUrl, { secure: true });

export default socket;



export function socket_init() {
    socket.on('connect', () => {
        console.log("==================== connected to socket =================================");
    })
}

export function socket_close() {
    if (socket && typeof socket.close === "function") {
        socket.close();
    }
}

