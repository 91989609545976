import types from './types'
import API from '../../axios/CaseTagApi'
import handleAPIErrorResponse from '../../utils/handleAPIResponseError'

import { message } from 'antd'

const setCaseTags = payload => ({
    type: types.SET_CASE_TAGS,
    payload
})

function getCaseTags() {
    return dispatch => {
       API.getCaseTags()
        .then(res => {
            const { data } = res
            if (data && !data.error) {
                const { caseTags } = data
                const mutableCaseTags = caseTags.filter(tag => !tag.immutable && tag[0] !== '*')
                dispatch(setCaseTags(mutableCaseTags))
            } else {
                message.warning('Unable to retrive case tags')
            }
        })
        .catch(error => {
            handleAPIErrorResponse(
                error,
                "Error occured while retrieving case tags"
            )
        })
    }
}

function addCaseTag(caseId, tag) {
    return () => {
        API.addCaseTag({ caseId, tag })
         .then(res => {
             const { data } = res
             if (data && !data.error) {
                message.success("Case tag added")
             }
         })
         .catch(error => {
             handleAPIErrorResponse(
                 error,
                 "Error occured while attempting to add case tag"
             )
         })
     }
}

function removeCaseTag(caseId, tag) {
    return () => {
        API.removeCaseTag({ caseId, tag })
         .then(res => {
             const { data } = res
             if (data && !data.error) {
                message.success("Case tag removed")
             }
         })
         .catch(error => {
             handleAPIErrorResponse(
                 error,
                 "Error occured while attempting to remove case tag"
             )
         })
     }
}

const actions = {
    getCaseTags,
    addCaseTag,
    removeCaseTag
}

export default actions
