import React from "react";
import { Menu, Badge, Spin } from "antd";
import { connect } from "react-redux";
import { Link, useRouteMatch, useLocation } from "react-router-dom";
import ScheduleDayIcon from "../../../assets/icons/ScheduleDayIcon";
import styled from 'styled-components'
import {
    FileDoneOutlined,
    FileUnknownOutlined,
    MessageOutlined,
    ReadOutlined,
    DatabaseOutlined,
    PieChartOutlined,
    ExclamationCircleOutlined,
    EyeOutlined,
    ReloadOutlined,
    SwapOutlined,
    TeamOutlined,
    UnorderedListOutlined,
    SearchOutlined,
    UserSwitchOutlined,
    HistoryOutlined,
} from "@ant-design/icons";

import { userConstants } from "../../../constants";
import { PhysicianTagEnum } from "../../../types/enums"
import DashBoardIcon from "../../../assets/icons/DashBoardIcon";
import CaseIcon from "../../../assets/icons/CaseIcon";
import ConsultedCaseIcon from "../../../assets/icons/ConsultedCaseIcon";
import config from '../../../config';

const StyledMenu = styled(Menu)`
    height: calc(100vh - 88px); // admittedly arbitrary value that seems to work.
    overflow-y: auto;
    overflow-x: hidden;
    & li.ant-menu-item {
        line-height: 44px;
    }
`

const StyledSpin = styled(Spin)`
  i {
    background-color: #7bad7b;
  }
`

const MainMenu = (props) => {
    let { url } = useRouteMatch();
    const location = useLocation();
    const {
        rxChangeCaseCount,
        rxRenewalCaseCount,
        incompleteCasesCount,
        MessageCount,
        additionalInfoCaseUpdateCount,
        referralCasesCount,
        caseTicketNotificationCount,
        pendingPrescriptionCaseNotification,
        physicianDetails,
        caseQueue
    } = props;

    const physicianTags = physicianDetails?.tags?.map(tag => tag.toLowerCase())
    const physicianNeedsCasesReviewed = physicianTags?.includes(PhysicianTagEnum.NEEDS_REVIEW)
    const isReviewingPhysician = physicianTags?.includes(PhysicianTagEnum.REVIEWER)
    const isLeadPhysician = physicianTags?.map(tag => tag.toLowerCase()).includes(PhysicianTagEnum.LEAD_PHYSICIAN)

    return (
        <StyledMenu
            mode="inline"
            style={{ borderRight: 0, width: "100%" }}
            selectedKeys={[location.pathname]}
            defaultSelectedKeys={["/dashboard"]}
        >
            <Menu.Item key="/dashboard" className="menu-item">
                <Link to={`${url}`}>
                    <span>
                        <DashBoardIcon />
                        Dashboard
                    </span>
                </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/physician/cases" className='menu-item'> 
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/cases`}>
                    <span>
                        <SearchOutlined />
                        Cases
                    </span>
                </Link>
            </Menu.Item>
            <Menu.Item
                key="/dashboard/physician/case-queue"
                className="menu-item"
            >
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/case-queue`}>
                    <Badge count={caseQueue?.length }>
                        <span style={{ paddingLeft: "20px" }}>
                            <UnorderedListOutlined className="sidebar-icons"/>
                            Case Queue
                        </span>
                    </Badge>
                </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/physician/caselist" className='menu-item'> 
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/caselist`}>
                    <Badge count={pendingPrescriptionCaseNotification}>
                        <span style={{ paddingLeft: 20 }}>
                            <CaseIcon />
                            Pending Prescription
                        </span>
                    </Badge>
                </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/physician/sync-cases" className="menu-item">
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/sync-cases`}>
                    <span style={{ paddingLeft: "20px" }}>
                        <CaseIcon />
                        Sync Cases
                    </span>
                </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/physician/additional-info-required" className="menu-item">
                <Link
                    to={`${url}/${userConstants.USER_PHYSICIAN}/additional-info-required`}
                >
                    <Badge count={additionalInfoCaseUpdateCount} offset={[-9, 1]}>
                        <span style={{ paddingLeft: "20px" }}>
                            <FileUnknownOutlined className="sidebar-icons" />
                            Additional Info Required
                        </span>
                    </Badge>
                </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/physician/consultedcase" className="menu-item">
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/consultedcase`}>
                    <span style={{ paddingLeft: "20px" }}>
                        <FileDoneOutlined className="sidebar-icons" />
                        Completed Consults
                    </span>
                </Link>
            </Menu.Item>
            <Menu.Item
                key="/dashboard/physician/consultedcase-noRX"
                className="menu-item"
            >
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/consultedcase-noRX`}>
                    <span style={{ paddingLeft: "20px" }}>
                        <ConsultedCaseIcon />
                        Consult Complete, No Rx
                    </span>
                </Link>
            </Menu.Item>
            <Menu.Item
                key="/dashboard/physician/updateprescribe"
                className="menu-item"
            >
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/updateprescribe`}>
                    <Badge count={rxChangeCaseCount}>
                        <span style={{ paddingLeft: "20px" }}>
                            <SwapOutlined className="sidebar-icons" />
                            Rx Change Requests
                        </span>
                    </Badge>
                </Link>
            </Menu.Item>
            <Menu.Item
                key="/dashboard/physician/rx-requests"
                className="menu-item"
            >
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/rx-change-requests`}>
                    <Badge count={rxRenewalCaseCount}>
                        <span style={{ paddingLeft: "20px" }}>
                            <ReloadOutlined className="sidebar-icons" />
                            Rx Renewal Requests
                        </span>
                    </Badge>
                </Link>
            </Menu.Item>
            { (isReviewingPhysician || physicianNeedsCasesReviewed) && 
                <Menu.Item
                key="/dashboard/physician/referrals"
                className="menu-item"
            >
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/referrals`}>
                    <Badge count={referralCasesCount}>
                        <span style={{ paddingLeft: "20px" }}>
                            <EyeOutlined className="sidebar-icons" />
                            { physicianNeedsCasesReviewed ? 'In Review' : 'Needs Review' } 
                        </span>
                    </Badge>
                </Link>
            </Menu.Item>
        }
            <Menu.Item
                key="/dashboard/physician/reassigned-cases"
                className="menu-item"
            >
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/reassigned-cases`}>
                        <span style={{ paddingLeft: "20px" }}>
                            <UserSwitchOutlined className="sidebar-icons"/>
                            Reassigned Cases
                        </span>
                </Link>
            </Menu.Item>
            <Menu.Item
                key="/dashboard/physician/incomplete-cases"
                className="menu-item"
            >
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/incomplete-cases`}>
                    <Badge count={incompleteCasesCount}>
                        <span style={{ paddingLeft: "20px" }}>
                            <ExclamationCircleOutlined className="sidebar-icons" />
                            Incomplete Cases
                        </span>
                    </Badge>
                </Link>
            </Menu.Item>
            { isLeadPhysician && (
                <Menu.Item
                    key="/dashboard/physician/other-physicians-cases"
                    className="menu-item"
                >
                    <Link to={`${url}/${userConstants.USER_PHYSICIAN}/other-physicians-cases`}>
                        <Badge count={0}>
                            <span style={{ paddingLeft: "20px" }}>
                                <TeamOutlined className="sidebar-icons" />
                                Other Physicians' Cases
                            </span>
                        </Badge>
                    </Link>
                </Menu.Item>
            )}
            <Menu.Item
                    key="/dashboard/physician/history"
                    className="menu-item"
                >
                    <Link to={`${url}/${userConstants.USER_PHYSICIAN}/history`}>
                        <Badge count={0}>
                            <span style={{ paddingLeft: "20px" }}>
                                <HistoryOutlined className="sidebar-icons" />
                                History
                            </span>
                        </Badge>
                    </Link>
                </Menu.Item>
            <Menu.Item key="/dashboard/physician/availability" className="menu-item">
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/availability`}>
                    <span>
                        <ScheduleDayIcon />
                        My Availability
                    </span>
                </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/physician/messages" className="menu-item">
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/messages`}>
                    <Badge count={MessageCount}>
                        <span>
                            <MessageOutlined className="sidebar-icons" />
                            Messages
                        </span>
                    </Badge>
                </Link>
            </Menu.Item>
            <Menu.Item
                key="/dashboard/physician/templates"
                className="menu-item"
            >
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/templates`}>
                    <span>
                        <ReadOutlined className="sidebar-icons" />
                        Templates
                    </span>
                </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/physician/articles" className="menu-item">
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/articles`}>
                    <span>
                        <ReadOutlined className="sidebar-icons" />
                        Articles
                    </span>
                </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/physician/reports" className="menu-item">
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/reports`}>
                    <span>
                        <PieChartOutlined className="sidebar-icons" />
                        Reports
                    </span>
                </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/physician/case-tickets" className="menu-item">
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/case-tickets`}>
                    <Badge count={caseTicketNotificationCount}>
                        <span>
                            <DatabaseOutlined className="sidebar-icons" />
                            Case Tickets
                        </span>
                    </Badge>
                </Link>
            </Menu.Item>
            <Menu.Item key="sha" onClick={() => window.navigator?.clipboard?.writeText?.(config.LATEST_SHA)}>
                sha: #{config.LATEST_SHA}
            </Menu.Item>
        </StyledMenu>
    );
};

function mapStateToProps(state) {
    const { waitingCasesCount } = state.waitingRoom;
    const { MessageCount } = state.message;
    const { additionalInfoCaseUpdateCount } = state.additionalInfoRequired;
    const { referralCasesCount } = state.referrals
    const { rxChangeCaseCount } = state.rxChange;
    const { rxRenewalCaseCount } = state.rxRenewal;
    const { incompleteCasesCount } = state.incompleteCases;
    const { caseTicketNotificationCount } = state.caseTickets;
    const { pendingPrescriptionCaseNotification } = state.notifications;
    const { physicianDetails } = state.physicianDetails
    const { caseQueue } = state.caseQueue
    return {
        waitingCasesCount,
        MessageCount,
        additionalInfoCaseUpdateCount,
        referralCasesCount,
        rxChangeCaseCount,
        rxRenewalCaseCount,
        incompleteCasesCount,
        caseTicketNotificationCount,
        pendingPrescriptionCaseNotification,
        physicianDetails,
        caseQueue
    };
}

export default connect(mapStateToProps)(MainMenu);
