import types from "./types";

const initState = {
    providerPin: null,
    providerOTPCode: null,
    consultationCompleted: false,
    allPrescriptionsValid: false,
    selectedPharmacy: null,
    showChangePharmacyModal: false,
    prescriptions: null,
    pharmacy: null,
    isNewDefaultPharmacy: false,
    isRxMismatch: false,
    prescribed: false,
    readyToComplete: false,
    APMedicineDictionary: [],
    rxComparisonMedicationsLoading: false,
    useTFA: false,
    dispenseUnits: [],
    visitNote: null,
    pdmpViewed: false
};

export default function prescriptionQueue(state = initState, action) {
    switch (action.type) {
        case types.SET_PHARMACY:
            return {
                ...state,
                pharmacy: action.payload
            }
        case types.SET_IS_NEW_DEFAULT_PHARMACY:
            return {
                ...state,
                isNewDefaultPharmacy: action.payload
            }
        case types.SET_DISPENSE_UNITS:
            return {
                ...state,
                dispenseUnits: action.payload
            }
        case types.SET_CONSULTATION_COMPLETED:
            return {
                ...state,
                consultationCompleted: action.payload
            }
        case types.SET_IS_RX_MISMATCH:
            return {
                ...state,
                isRxMismatch: action.payload
            }
        case types.ADD_PRESCRIPTION_TO_QUEUE:
            const index = Date.now()

            return {
                ...state,
                allPrescriptionsValid: false,
                prescriptions: {
                    ...state.prescriptions,
                    [index]: { _id: index, startDate: new Date(), valid: false }
                }
            };
        case types.SAVE_QUEUED_PRESCRIPTION:
            if (action.payload._id) {
                const nextState = {
                    ...state,
                    prescriptions: {
                        ...state.prescriptions,
                        [action.payload._id]: { 
                            ...state.prescriptions?.[action.payload._id], 
                            ...action.payload
                        }
                    }
                };
    
                
                const invalidPrescriptions = Object.values(nextState.prescriptions).filter(prescription => !prescription.valid)
                const allPrescriptionsValid = invalidPrescriptions.length === 0 
    
                return { ...nextState, allPrescriptionsValid }
            }

            return state   
        case types.REMOVE_PRESCRIPTION_FROM_QUEUE:
            const currentlyQueuedPrescriptions = { ...state.prescriptions }
            delete currentlyQueuedPrescriptions[action.payload]
            return {
                ...state,
                prescriptions: currentlyQueuedPrescriptions
            };
        case types.CLEAR_QUEUED_PRESCRIPTION:
            return {
                ...state,
                allPrescriptionsValid: false,
                prescriptions: {
                    ...state.prescriptions,
                    [action.payload]: {
                        _id: action.payload,
                        requestedMedication: state.prescriptions[action.payload]?.requestedMedication,
                        valid: false
                    }
                }
            }
        case types.RESET_PRESCRIPTION_QUEUE:
            return initState
        case types.SET_ALL_PRESCRIPTIONS_VALID:
            return {
                ...state,
                allPrescriptionsValid: action.payload
            }
        case types.SET_PRESCRIPTION_QUEUE:
            if (state.prescriptions) return state
            return {
                ...state,
                prescriptions: action.payload
            }
        case types.SET_AP_MEDICINE_DICTIONARY:
            return {
                ...state,
                APMedicineDictionary: action.payload
            }
        case types.SET_PROVIDER_PIN:
            return {
                ...state,
                providerPin: action.payload
            }
        case types.SET_PROVIDER_OTP_CODE:
            return {
                ...state,
                providerOTPCode: action.payload
            }
        case types.SET_USE_TFA:
            return {
                ...state,
                useTFA: action.payload
            }
        case types.EVALUATE_READY_TO_COMPLETE:
            const providerHasPinSet = action.payload.pinSet
            const providerIsAuthorizedToRxControlledSubstances = action.payload.idVerified
            const queuedControlledSubstances = Object.values(state.prescriptions).filter(medication => medication.schedule && medication.schedule !== "0")
            const hasControlledSubstance =  !!queuedControlledSubstances.length
            const allControlledSubstancesReadyToSign = !queuedControlledSubstances.find(medication => !medication.readyToSign)
            const controlledSubstaceRequirementsMet =  allControlledSubstancesReadyToSign && (state.useTFA || state.providerOTPCode?.length === 6)
            const isEPCSReady = hasControlledSubstance && providerIsAuthorizedToRxControlledSubstances && state.providerPin?.length === 4 && controlledSubstaceRequirementsMet
            const isNonEPCSReady = !hasControlledSubstance && ((providerHasPinSet && state.providerPin?.length === 4) || !providerHasPinSet)

            return {
                ...state,
                readyToComplete: isEPCSReady || isNonEPCSReady
            }
        case types.SET_VISIT_NOTE:
            return {
                ...state,
                visitNote: action.payload
            }
        case types.REGISTER_PDMP_VIEW:
            return {
                ...state,
                pdmpViewed: true
            }
        default:
            return state;
    }
}
