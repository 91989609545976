/* eslint-disable react-hooks/exhaustive-deps */
//component to register case for patient

import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import { Layout } from "antd";
import socket, { socket_init } from "../../axios/socket";

//import CSS
import "./Case.css";

//import components
import MainHeader from "../../layout/CaseHeader";
import ScheduledConsultation from "./scheduledConsultation";
import SocketContext from "../../context/socketContext";

const Case = () => {
  let { path } = useRouteMatch();
  const initCasePage = () => {
    if (
      window.localStorage.getItem("token") !== null &&
      window.localStorage.getItem("caseId") !== null
    ) {
      socket.connect();
      socket_init();
      return () => {
        if (socket && typeof socket.close === "function") {
          socket.close();
        }
      };
    }
    return () => {
      if (socket && typeof socket.close === "function") {
        socket.close();
      }
    };
  };

  useEffect(initCasePage, []);

  return window.localStorage.getItem("token") !== null &&
    window.localStorage.getItem("caseId") !== null ? (
    <SocketContext.Provider value={socket}>
      <Layout className="case-layout">
        <MainHeader />
        <Layout className="case-inner-layout">
          <Switch>
            <Route
              path={`${path}/scheduled-call/:id`}
              render={(props) => <ScheduledConsultation {...props} />}
            />
          </Switch>
        </Layout>
      </Layout>
    </SocketContext.Provider>
  ) : (
    <Redirect to="/login-case" />
  );
};

export default Case;
