import React from "react";
import { Layout } from "antd";

const { Sider } = Layout;
const SiderPage = ({ children, collapsed, changeCollapseState }) => {

  const onSidebarSizeChange = (status) => {
    changeCollapseState(status);
  };

  return (
    <Sider
      className="main-sider"
      trigger={null}
      collapsible
      breakpoint="lg"
      collapsedWidth={0}
      collapsed={collapsed}
      onBreakpoint={onSidebarSizeChange}
    >
      {children}
    </Sider>
  );
};

export default SiderPage;
