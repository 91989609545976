import { useState, useEffect } from 'react';

import { message } from 'antd'

import PhysicianApi from '../axios/physician/PhysicianApi'

export const useCaseDetails = caseId => {
    const [caseDetails, setCaseDetails] = useState(null)
    const [caseDetailsLoading, setCaseDetailsLoading] = useState(false)

    const fetchCaseDetails = () => {
        setCaseDetailsLoading(true)
        PhysicianApi.getCaseDetailsById(caseId)
            .then(({ data: { payload } }) => setCaseDetails(payload))
            .catch(e => {
                console.log(e)
                message.error("Unable to fetch caseDetails")
            })
            .finally(() => setCaseDetailsLoading(false))
    }

    useEffect(() => {
        caseId && fetchCaseDetails(caseId)
    }, [caseId]) 

    return { caseDetails, setCaseDetails, caseDetailsLoading, fetchCaseDetails }
}