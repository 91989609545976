import Axios from "../Axios";
import config from "../../config";

const { api } = config;

export default class API {
  static getToken() {
    const token = window.localStorage.getItem("token");
    return token;
  }

  static getResetPasswordToken() {
    const resetToken = window.localStorage.getItem("resetToken");
    return resetToken;
  }

  static physicianUpdate(model, id) {
    var bodyFormData = new FormData();
    const token = this.getToken();
    for (var key in model) {
      bodyFormData.append(key, model[key]);
    }
    return Axios({
      url: `${api.baseUrl}/physician/physician/`,
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: bodyFormData,
    });
  }
  static physicianData() {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/physician/physician`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getCaseList(params) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/physician/cases/`,
      method: "GET",
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getReferralCases() {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/physicians/cases/referrals`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getSyncCaseList(params) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/physician/cases/list/sync`,
      method: "GET",
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getRxChangeRequests(params) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/cases/rx-change-requests`,
      method: "GET",
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getRxRenewalRequests(params) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/cases/rx-renewal-requests`,
      method: "GET",
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getRxRenewalRequestsCount(params) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/cases/rx-renewal-requests/count`,
      method: "GET",
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getCaseCount(params) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/physician/get-case-count/`,
      method: "GET",
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getScheduledList() {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/consultation/consultations/`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static gotoRoom(id) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/video/token/${id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static updateCaseStatus(caseId, data) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/physician/cases/${caseId}`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
  }

  static updateCasePrescription(caseId, data) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/physician/cases/${caseId}`,
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
  }

  static getScheduleDays() {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/physician/get-availability/`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getAvailability(physicianId) {
    const token = this.getToken();

    return Axios({
      url: `${api.baseUrl}/physicians/${physicianId}/availability`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static addTimeFrame(physicianId, availabilityType, timeFrame) {
    const token = this.getToken();

    return Axios({
      url: `${api.baseUrl}/physicians/${physicianId}/availability/time-frame`,
      method: "POST",
      data: { [availabilityType]: timeFrame },
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  static deleteTimeFrame(physicianId, availabilityType, timeFrameId) {
    const token = this.getToken();

    return Axios({
      url: `${api.baseUrl}/physicians/${physicianId}/availability/time-frame`,
      method: "DELETE",
      params: { [availabilityType]: timeFrameId },
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  static updateTimeFrame(physicianId, availabilityType, timeFrameId, updates) {
    const token = this.getToken();

    return Axios({
      url: `${api.baseUrl}/physicians/${physicianId}/availability/time-frame`,
      method: "PUT",
      data: { [availabilityType]: { timeFrameId, ...updates } },
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  static updateTimeZone(physicianId, timeZone) {
    const token = this.getToken();

    return Axios({
      url: `${api.baseUrl}/physicians/${physicianId}/availability/time-zone`,
      method: "PUT",
      data: { preferredIANATimeZoneName: timeZone },
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  static updateScheduleDays(data) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/physician/update-schedules/`,
      method: "PUT",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static rescheduleConsultation(data) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/consultation/reschedule/`,
      method: "POST",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static startConsultation(data) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/consultation/initiate/`,
      method: "PUT",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getAllTemplateMessageForCategory(params) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/message-template/message-template-all/`,
      method: "GET",
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getTemplates(type, { search, filters } = {}) {
    const token = this.getToken();

    return Axios({
      url: `${api.baseUrl}/templates`,
      method: "GET",
      params: { type, search, ...filters },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getTemplatesByCloseness(data) {
    const token = this.getToken();

    return Axios({
      url: `${api.baseUrl}/templates/closeness`,
      method: "POST",
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static addTemplate(type, data) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/templates`,
      method: "POST",
      data: data,
      params: { type },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static deleteTemplate(id) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/templates/${id}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static updateTemplate(id, data) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/templates/${id}`,
      method: "PUT",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static toggleFavoriteTemplate(id) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/templates/${id}/favorite`,
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static togglePrivateTemplate(id) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/templates/${id}/private`,
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getMessageCount() {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/physician/get-message-count/`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static passwordResetRequest(data) {
    return Axios({
      url: `${api.baseUrl}/user/password-reset-request/`,
      method: "POST",
      data: data,
    });
  }

  static passwordReset(data) {
    const resetPasswordToken = this.getResetPasswordToken();
    return Axios({
      url: `${api.baseUrl}/user/password-reset/`,
      method: "POST",
      data: data,
      headers: {
        Authorization: `Bearer ${resetPasswordToken}`,
      },
    });
  }

  static changePassword(data) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/physician/reset-password`,
      method: "POST",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static updatePatientDetails(id, data) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/case/case/${id}`,
      method: "PUT",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static updateMedicalInformation(data, id) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/physician/update-case-details/${id}`,
      method: "PATCH",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getRxMedications(params) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/medications/medication`,
      method: "GET",
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getCaseNotification(params) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/physician/case-notifications/`,
      method: "GET",
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getCaseDetailsById(caseId) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/physician/cases/${caseId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static clearCaseNotification(caseId) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/physician/clear-notification/${caseId}`,
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static setTimeZoneOffset(data) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/physician/update-offset/`,
      method: "PATCH",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static updateCasePrescriptionStatus(caseId, data) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/physician/cases/${caseId}`,
      method: "PATCH",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static addPhysicianNote(caseId, data) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/physician/new-note/${caseId}`,
      method: "POST",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static updatePhysicianNote(noteId, data) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/physician/update-note/${noteId}`,
      method: "PATCH",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getUnreadNotesCount(caseId) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/physician/unread-notes-count/${caseId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getRxRequestNotes(caseId) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/physician/get-rx-request-notes/${caseId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static clearUnreadNotesNotification(caseId) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/physician/clear-unread-notes/${caseId}`,
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getNotes(caseId) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/physician/get-notes-of-case/${caseId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static deleteNote(noteId) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/physician/delete-note/${noteId}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getMissedConsultsList() {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/physician/past-consults/`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getPhoneCallToken() {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/voice/token`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getLmsArticleList(params) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/lms/article-list`,
      method: "GET",
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  static getCaseTicketList(params) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/ticket/get-ticket-for-physician`,
      method: "GET",
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static viewLmsArticle(id) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/lms/view-article/${id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  static getCaseTicketNotifications() {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/ticket/case-ticket-notifications`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getCaseTicketClearNotifications(params) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/ticket/clear-ticket-notifications`,
      method: "GET",
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static markTicketAsRead(data) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/ticket/mark-ticket-as-read`,
      method: "PUT",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getPhysicianMonthlyReports(params) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/report/physicians-case-count`,
      method: "GET",
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static updateAppointmentStatus(caseId, status) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/cases/${caseId}/appointments`,
      method: "PATCH",
      data: { status },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static rejectCaseReview(data) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/v2/physician/case-review/reject`,
      method: "PUT",
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  
  static getOtherPhysiciansCases(params) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/v2/physician/cases/other-physicians`,
      method: "GET",
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getCaseQueue(params) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/cases/queue`,
      method: "GET",
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static selfAssignCase(data) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/v2/physician/cases/self-assign`,
      method: "PUT",
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getSignedUploadUrl(caseId, fileName) {
    return Axios
      .get(`/cases/${caseId}/documents/upload-url`, {
        params: { fileName },
        headers: {
          Authorization: `Bearer ${this.getToken()}`
        }
      })
  }

  static addAdditionalDocumentUrlToCase(caseId, url) {
    return Axios
      .patch(`/cases/${caseId}/documents`, {
        name: `${Date.now()}`,
        url 
      }, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`
        }
      })
  }
  
  static sendQueueSMSNotification() {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/physicians/queue-notification/`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getPhysicians() {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/v2/physician`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getViewedCaseHistory() {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/cases/history`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
