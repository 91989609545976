import React, { createContext, useCallback } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';

export const TablePreferencesContext = createContext();

const defaultPreferences = { currentPage: 1, tablePageSize: 100, dateSortOrder: 'ascend' };

export const TablePreferencesProvider = ({ children }) => {
  const [tablePreferences, setTablePreferences] = useLocalStorage('table-preferences', {}, JSON.parse);

  const getTablePreferences = useCallback((key, defaults=defaultPreferences) => {
    if (!(key in tablePreferences)) {
      updateTablePreferences(key, defaults);
      return defaults;
    }
    else {
      return tablePreferences[key];
    }
    // eslint-disable-next-line
  }, [tablePreferences])

  const updateTablePreferences = (key, updates) => {
    setTablePreferences(t => ({
      ...t,
      [key]: t[key] 
        ? { ...t[key], ...updates } 
        : { ...updates }
    }))
  }

  return <TablePreferencesContext.Provider value={{ getTablePreferences, updateTablePreferences }}>{children}</TablePreferencesContext.Provider>
}

