import moment from "moment";

export const dateOfBirthFormatter = (dob) => {
    return moment.utc(dob).format("MM/DD/YYYY");
}

export const dateTimeFormatter = (date) => {
    return moment(date).format("MM/DD/YYYY hh:mm A");
}

export const timeFormatter = (date) => {
    return moment(date).format("hh:mm A");
}