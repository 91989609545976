import React from "react";
import {
  UserOutlined,
  MenuOutlined,
  MedicineBoxOutlined,
  MessageOutlined,
  IdcardOutlined
} from "@ant-design/icons";
import { Layout, Menu, Badge, Tooltip, message } from "antd";
import { Link, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import LogoIcon from "../../../assets/icons/LogoIcon";
import { history } from "../../../helpers/history";
import authentication from "../../../redux/authentication";
import notifications from "../../../redux/notifications"
import { socket_close } from "../../../axios/socket";
import API from "../../../axios/apiHelper";
import config from '../../../config';

const { Header } = Layout;
const { SubMenu } = Menu;

const HeaderPage = ({
  MessageCount, 
  actions, 
  setDrawerVisibility,
  screenWidth,
  userName,
  _rxRequests,
  rxErrors,
  provider,
  idVerified,
  pinSet,
  tfaActivated,
  beginIdentityVerification
}) => {
  let { url } = useRouteMatch();

  const PROVIDER_HANDLES_TESTO_CASES = provider?.teleMedicineCategories?.includes('Testosterone')

  const LogOut = () => {
    API.logOut()
      .then((res) => {
        const { data } = res;
        if (data.success) {
          socket_close();
          const tablePreferences = localStorage.getItem('table-preferences');
          window.localStorage.clear();
          localStorage.setItem('table-preferences', tablePreferences);
          actions.setUserLogout({});
          history.push("/");
        } else {
          message.error("unable to logout,Please try again!");
        }
      })
      .catch(() => {
        message.error("unable to logout,Please try again!");
      });
  };

  const showDrawer = () => {
    setDrawerVisibility();
  };

  return (
    <>
      <Header className="header-physician-dashboard">
        <div className="home-page-header-logo">
          {screenWidth < 1000 ? (
            <div onClick={showDrawer} className="home-page-header-icon">
              <MenuOutlined />
            </div>
          ) : (
            ""
          )}
          <LogoIcon width="160px" height="55px" />
          <span style={{ width: '100%', marginLeft: 60, lineHeight: 'normal', fontSize: '.5rem'  }} >#:{config.LATEST_SHA}</span>
        </div>
        <Menu mode="horizontal">
          {
            config.allowIdVerification === 'true' && 
            !!provider && 
            PROVIDER_HANDLES_TESTO_CASES && 
            (!idVerified || !pinSet || !tfaActivated) && 
              (
                <Badge count={'!'} className="header-message-count-icon">
                    <Tooltip title='Controlled Substance Id Verification'>
                      <IdcardOutlined 
                        className="header-message-icon" 
                        onClick={beginIdentityVerification}
                      />
                    </Tooltip>
                </Badge>
              )
          }
          <Badge count={MessageCount} className="header-message-count-icon">
            <Link to={`${url}/${window.localStorage.getItem("type")}/messages`}>
              <Tooltip title='Patient Messages'>
                <MessageOutlined className="header-message-icon" />
              </Tooltip>
            </Link>
          </Badge>
          <Badge count={_rxRequests?.length + rxErrors?.length} className="header-message-count-icon">
            <Link to={`${url}/${window.localStorage.getItem("type")}/rx-requests`}>
              <Tooltip title='Rx Requests'>
                <MedicineBoxOutlined className="header-message-icon" />
              </Tooltip>
            </Link>
          </Badge>
          <SubMenu
            key="userboard"
            className="header-sub-menu-item-user-board"
            title={
              <span>
                {screenWidth < 800 ? (
                  <UserOutlined />
                ) : (
                  <>
                    {" "}
                    <UserOutlined /> {userName}
                  </>
                )}
              </span>
            }
          >           
              <Menu.Item key="1">
                <Link
                  to={`${url}/${window.localStorage.getItem("type")}/myaccount`}
                >
                  My Account
                </Link>
              </Menu.Item>         

            <Menu.Item key="2" onClick={(event) => LogOut()}>
              LogOut
            </Menu.Item>
          </SubMenu> 
        </Menu>
      </Header>
    </>
  );
};

function mapStateToProps(state) {
  const { MessageCount } = state.message;
  const { rxRequestNotifications } = state.notifications
  const { rxRequests: _rxRequests, rxErrors } = state.rxRequests
  return { MessageCount, rxRequestNotifications, _rxRequests, rxErrors };
}

const mapDispatchToProps = (dispatch) => {
  const { setUserLogout } = authentication.actions;
  const { clearRxRequestNotifications } = notifications.actions

  return {
    actions: bindActionCreators(
      {
        setUserLogout,
        clearRxRequestNotifications
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderPage);
