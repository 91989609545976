import types from "./types";
import API from "../../axios/physician/PhysicianApi";
import handleAPIErrorResponse from "../../utils/handleAPIResponseError";
import { dateTimeFormatter } from "../../utils/dateFormatter";

import { message } from "antd";

const setCaseCountLoading = (status) => ({
    type: types.SET_CASE_COUNT_LOADING,
    data: status,
});

const setCaseListLoading = (status) => ({
    type: types.SET_CASE_LIST_LOADING,
    data: status,
});

const setPendingPrescriptionCaseList = (payload) => ({
    type: types.SET_PENDING_PRESCRIPTION_CASE_LIST,
    payload
})

const setPendingPrescriptionCaseCount = (payload) => ({
    type: types.SET_PENDING_PRESCRIPTION_CASE_COUNT,
    payload
})

function getPendingPrescriptionCaseList(status) {
    return (dispatch) => {
        dispatch(setCaseListLoading(true));
        API.getCaseList(status).then(
            (res) => {
                const { data } = res;
                if (data && data.success) {
                    const { payload } = data;
                    if (payload) {
                        const mappedData = payload.data.map((item) => {
                            return {
                                ...item,
                                key: item._id,
                                name: item.patientName,
                                caseno: item.caseId,
                                categoryname: item.category,
                                createddate: item.createdDate
                                    ? dateTimeFormatter(item.createdDate)
                                    : "",
                                status: item.status,
                                statusDisplayName: item.statusDisplayName,
                                consultationType: item.consultationType
                                    ? item.consultationType
                                    : null,
                                consultationStart: item.consultationStart
                                    ? item.consultationStart
                                    : null,
                            };
                        });
                        dispatch(setPendingPrescriptionCaseList(mappedData))
                        dispatch(setPendingPrescriptionCaseCount(payload.count))
                    }
                    message.success("Cases in Pending Prescrition has been updated");
                } else {
                    message.warning("Unable to update cases in pending prescription");
                }
                dispatch(setCaseListLoading(false));
            }).catch(
                (error) => {
                    handleAPIErrorResponse(error, "Error occured while updating cases in pending prescription");
                    dispatch(setCaseListLoading(false));
                });
    };
}

function getPendingPrescriptionCaseCount(status) {
    return (dispatch) => {
        dispatch(setCaseCountLoading(true));
        API.getCaseCount(status).then(
            (res) => {
                const { data } = res;
                if (data && data.success) {
                    const { payload } = data;
                    if (payload) {
                        const { caseCount } = payload;
                        dispatch(setPendingPrescriptionCaseCount(caseCount))
                    }
                } else {
                    message.warning("Unable to update cases in pending prescription");
                }
                dispatch(setCaseCountLoading(false));
            }).catch(
                (error) => {
                    handleAPIErrorResponse(error, "Error occured while updating cases in pending prescription");
                    dispatch(setCaseCountLoading(false));
                });
    };
}

const actions = {
    setCaseCountLoading,
    setCaseListLoading,
    setPendingPrescriptionCaseList,
    setPendingPrescriptionCaseCount,
    getPendingPrescriptionCaseList,
    getPendingPrescriptionCaseCount,
};

export default actions;