import React, { useEffect, useContext } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Spin, Badge, Button } from "antd";
import { LoadingOutlined, MessageOutlined } from "@ant-design/icons";
import { dateTimeFormatter } from "../../utils/dateFormatter";

import incompleteCases from "../../redux/incompleteCases";

// commons
import { userConstants } from "../../constants";
import { history } from "../../helpers";
import { sorters } from "../../utils/sorters";

import { TablePreferencesContext } from "../../context/TablePreferencesProvider";

const TABLE_NAME = "incomplete cases";

const IncompleteCases = (props) => {
  const {
    actions,
    incompleteCases,
    incompleteCasesCount,
    incompleteCasesLoading,
  } = props;
  const { getTablePreferences } = useContext(
    TablePreferencesContext
  );
  const { currentPage, tablePageSize, dateSortOrder } =
    getTablePreferences(TABLE_NAME);

  const columns = [
    {
      title: "Case No",
      dataIndex: "caseId",
      key: "caseno",
      sorter: (a, b) => sorters.alphanumeric(a.caseId, b.caseId),
    },
    {
      title: "Patient",
      dataIndex: "patientName",
      key: "name",
      sorter: (a, b) => sorters.alphanumeric(a.patientName, b.patientName),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "categoryname",
      sorter: (a, b) => sorters.alphanumeric(a.categoryName, b.categoryName),
    },
    {
      title: "Last Updated",
      dataIndex: "updatedDate",
      key: "updateDate",
      sortOrder: dateSortOrder,
      render: (_, caseDetails) => dateTimeFormatter(caseDetails.updatedDate),
      sorter: (a, b) =>
        sorters.dates(new Date(a.updatedDate), new Date(b.updatedDate)),
    },
    {
      title: "Status",
      dataIndex: "statusDisplayName",
      key: "status",
      sorter: (a, b) => sorters.alphanumeric(a.status, b.status),
    },
    {
      dataIndex: "",
      width: 100,
      key: "chat-messages",
      render: (eachCase, record) => (
        <>
          {eachCase && eachCase.caseId && (
            <Badge
              count={
                eachCase.unreadChatNotification &&
                eachCase.unreadChatNotification
              }
            >
              <Button
                shape="circle"
                icon={<MessageOutlined />}
                onClick={(event) => {
                  event.stopPropagation();
                  navigateToMessages(eachCase.caseId);
                }}
              />
            </Badge>
          )}
        </>
      ),
    },
  ];

  // eslint-disable-next-line
  useEffect(() => actions.getIncompleteCases(), []);

  const navigateToMessages = (caseId) => {
    history.push(`/dashboard/${userConstants.USER_PHYSICIAN}/messages`);
    actions.setChatCaseId(caseId);
  };

  const pushRouteCaseDetails = (caseId) => {
    history.push(
      `/dashboard/${userConstants.USER_PHYSICIAN}/caseview/${caseId}`, {
        from: 'incomplete-cases'
      }
    );
  };

  const handleCaseListClick = (event, record) => {
    event.stopPropagation();
    pushRouteCaseDetails(record.caseId);
  };

  return (
    <div className="consulted-case">
      <div className="waiting-room-case-list-part-heading">
        <div>
          <h1 style={{ marginBottom: "0" }}>Incomplete Cases</h1>
          <h4>No action taken within 24 hours of starting a consultation</h4>
        </div>
      </div>
      <div className="consulted-case-no-of-patient-list-data">
        <Spin
          size="large"
          spinning={incompleteCasesLoading}
          indicator={<LoadingOutlined spin />}
        >
          <div>
            <Table
              className="wating-room-table"
              columns={columns}
              dataSource={[...incompleteCases]}
              pagination={{
                total: incompleteCasesCount,
                showTotal: (total) =>
                  `Total ${total} Incomplete Case${total > 1 ? "s" : ""}`,
                pageSizeOptions: ["5", "10", "20", "50", "100"],
                showSizeChanger: true,
                current: currentPage,
                pageSize: tablePageSize,
              }}
              scroll={{ x: 900 }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) =>
                    handleCaseListClick(event, record, rowIndex),
                };
              }}
            />
          </div>
        </Spin>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { incompleteCases, incompleteCasesCount, incompleteCasesLoading } =
    state.incompleteCases;

  return {
    incompleteCases,
    incompleteCasesCount,
    incompleteCasesLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { getIncompleteCases } = incompleteCases.actions;
  return {
    actions: bindActionCreators(
      {
        getIncompleteCases,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IncompleteCases);
