import React, { useState, useContext, useEffect } from "react";
import "./AdditionalInfoRequired.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Button, Spin, Badge } from "antd";
import moment from "moment";

import { history } from "../../helpers";
import { userConstants } from "../../constants";
import {
  BellFilled,
  LoadingOutlined,
  MessageOutlined,
} from "@ant-design/icons";

// import components
import CaseUpdateComparisonComponent from "../../components/CaseUpdateComparisonComponent/CaseUpdateComparisonComponent";
import CaseSearchAndFilterComponent from "../../components/CaseSearchAndFilterComponent/CaseSearchAndFilterComponent";
import additionalInfoRequired from "../../redux/additionalInfoRequired";
import messageStore from "../../redux/message";
import { sorters } from '../../utils/sorters';
import { TablePreferencesContext } from '../../context/TablePreferencesProvider';

const TABLE_NAME = 'additional info';

const AdditionalInfoRequired = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [caseDetailsComparisonId, setCaseDetailsComparisonId] = useState(null);
  const [viewCaseDetailsComparisonModal, setViewCaseDetailsComparisonModal] =
    useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [caseListFilter, setCaseListFilter] = useState({});
  const { getTablePreferences, updateTablePreferences } = useContext(TablePreferencesContext);
  const { currentPage, tablePageSize, dateSortOrder } = getTablePreferences(TABLE_NAME, { currentPage: 1, tablePageSize: 100, dateSortOrder: 'descend' });

  const {
    actions,
    statesList,
    additionalInfoCaseList,
    telemedicineCategoriesList,
    additionalInfoCaseListLoading,
    additionalInfoCount,
    additionalInfoChatCount,
    additionalInfoCaseUpdateCount,
  } = props;

  const columns = [
    {
      title: "Case No",
      dataIndex: "caseno",
      key: "caseno",
      sorter: (a, b) => sorters.alphanumeric(a.caseId, b.caseId)
    },
    {
      title: "Patient Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => sorters.alphanumeric(a.patientName, b.patientName)
    },
    {
      title: "Category Name",
      dataIndex: "categoryname",
      key: "categoryname",
      sorter: (a, b) => sorters.alphanumeric(a.categoryName, b.categoryName)
    },
    {
      title: "Created Date",
      dataIndex: "createddate",
      key: "createddate",
      sorter: (a, b) => sorters.dates(new Date(a.createddate), new Date(b.createddate))
    },
    {
      title: "Status",
      dataIndex: "statusDisplayName",
      key: "status",
      sorter: (a, b) => sorters.alphanumeric(a.status, b.status)
    },
    {
      title: "Case Update Details",
      dataIndex: "",
      width: 160,
      key: "readNotifications",
      defaultSortOrder: dateSortOrder,
      sorter: (a, b) => sorters.alphanumeric(a.readNotifications, b.readNotifications),
      render: (eachCase, record) => (
        <>
          {eachCase && eachCase.readNotifications === false ? (
            <Badge dot>
              <Button
                shape="circle"
                type="ghost"
                icon={<BellFilled />}
                onClick={(event) => {
                  event.stopPropagation();
                  viewUpdatesInCase(eachCase);
                }}
              />
            </Badge>
          ) : (
            <>NA</>
          )}
        </>
      ),
    },
    {
      title: "Last Message At",
      dataIndex: "lastMessageToPatientAtDisplay",
      width: 160,
      key: "lastMessageToPatientAt",
      sorter: (a, b) => sorters.dates(
        a.lastMessageToPatientAt
          ? new Date(a.lastMessageToPatientAt)
          : null,
        b.lastMessageToPatientAt
          ? new Date(b.lastMessageToPatientAt)
          : null
      )
    },
    {
      title: "Messages",
      dataIndex: "",
      width: 160,
      key: "chat-messages",
      render: (eachCase, record) => (
        <>
          {eachCase && eachCase.caseId && (
            <Badge
              count={
                eachCase.unreadChatNotification &&
                eachCase.unreadChatNotification
              }
            >
              <Button
                shape="circle"
                icon={<MessageOutlined />}
                onClick={(event) => {
                  event.stopPropagation();
                  navigateToMessages(eachCase.caseId);
                }}
              />
            </Badge>
          )}
        </>
      ),
    },
  ];

  const navigateToMessages = (caseId) => {
    history.push(`/dashboard/${userConstants.USER_PHYSICIAN}/messages`);
    actions.setChatCaseId(caseId);
  };

  const viewUpdatesInCase = (updatedCase) => {
    setCaseDetailsComparisonId(updatedCase.caseno);
    setViewCaseDetailsComparisonModal(true);
  };

  const closeCaseDetailsComparisonModal = () => {
    setCaseDetailsComparisonId(null);
    setViewCaseDetailsComparisonModal(false);
  };

  const completeClearNotificationAndCaseListingUpdate = () => {
    setCaseDetailsComparisonId(null);
    setViewCaseDetailsComparisonModal(false);
  };

  const fetchAdditionalInfoCaseList = () => {
    const caseFilter = {
      sort: 'ascend',
      sortedBy: 'readNotifications'
    };

    caseFilter["index"] = currentPage;
    caseFilter["case-count"] = tablePageSize;
    caseFilter["status"] = "additional info required";
    caseFilter["additional-status"] = "invalid face photo,invalid photo id";
    if (searchValue !== null) {
      caseFilter["search-term"] = searchValue;
    }
    if (caseListFilter.teleMedicineCategories) {
      caseFilter["telemedicine-category-id"] =
        caseListFilter.teleMedicineCategories;
    }
    if (caseListFilter.state) {
      caseFilter["state-id"] = caseListFilter.state;
    }
    if (caseListFilter.caseid) {
      caseFilter["case-id"] = caseListFilter.caseid;
    }
    if (caseListFilter.patientid) {
      caseFilter["patient-id"] = caseListFilter.patientid;
    }
    if (caseListFilter.type) {
      caseFilter["type"] = caseListFilter.type;
    }
    if (caseListFilter.createddate) {
      caseFilter["created-date"] =
        caseListFilter.createddate &&
        moment(caseListFilter.createddate).format("YYYY-MM-DD");
    }

    actions.getAdditionalInfoCaseList(caseFilter);
  };
  
  // eslint-disable-next-line
  useEffect(fetchAdditionalInfoCaseList, [
    searchValue,
    caseListFilter,
    currentPage,
    tablePageSize,
    dateSortOrder,
    additionalInfoChatCount,
    additionalInfoCaseUpdateCount,
  ]);

  const pushRouteCaseDetails = (caseId) => {
    history.push(
      `/dashboard/${userConstants.USER_PHYSICIAN}/caseview/${caseId}`,
      { from: 'additional-info-required'}
    );
  };

  const handleCaseListClick = (event, record) => {
    event.stopPropagation();
    pushRouteCaseDetails(record.caseno);
  };
  
  const handleIntialTableCount = () => {
    updateTablePreferences(TABLE_NAME, { currentPage: 1, tablePageSize: 100 })
  };

  const handleOk = (filterData) => {
    setCaseListFilter(filterData);
    setShowModal(false);
    handleIntialTableCount();
  };

  const searchCase = (value) => {
    setSearchValue(value);
    handleIntialTableCount();
  };

  const handleCancel = () => {
    setCaseListFilter({});
    setShowModal(false);
    handleIntialTableCount();
  };

  const handleCancelCross = () => {
    setShowModal(false);
  };

  const onChangePage = (page) => {
    updateTablePreferences(TABLE_NAME, { currentPage: page })
  };

  const onPageSizeChange = (_, size) => {
    updateTablePreferences(TABLE_NAME, { currentPage: 1, tablePageSize: size })
  };

  return (
    <div className="consulted-case">
      <div className="waiting-room-case-list-part-heading">
        <h1>Additional Info Required</h1>
        <CaseSearchAndFilterComponent
          searchCase={searchCase}
          showModal={showModal}
          setShowModal={setShowModal}
          handleCancelCross={handleCancelCross}
          handleCancel={handleCancel}
          handleOk={handleOk}
          telemedicines={telemedicineCategoriesList}
          states={statesList}
          hideFields={['physician']}
        />
      </div>
      <div className="consulted-case-no-of-patient-list-data">
        <Spin
          size="large"
          spinning={additionalInfoCaseListLoading}
          indicator={<LoadingOutlined spin />}
        >
          <div>
            <Table
              className="wating-room-table"
              columns={columns}
              onChange={(pagination, filters, sorter) => { updateTablePreferences(TABLE_NAME, { dateSortOrder: sorter.order || 'descend' }) }}
              pagination={{
                total: additionalInfoCount,
                showTotal: () => `Total ${additionalInfoCount} cases`,
                current: currentPage,
                pageSize: tablePageSize,
                pageSizeOptions: ["5", "10", "20", "50", "100"],
                showSizeChanger: true,
                onChange: onChangePage,
                onShowSizeChange: onPageSizeChange,
              }}
              dataSource={additionalInfoCaseList}
              scroll={{ x: 900 }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    handleCaseListClick(event, record, rowIndex);
                  },
                };
              }}
            />
          </div>
        </Spin>
      </div>
      <CaseUpdateComparisonComponent
        modalVisible={viewCaseDetailsComparisonModal}
        caseId={caseDetailsComparisonId}
        setModalVisible={closeCaseDetailsComparisonModal}
        clearCaseNotificationCallback={
          completeClearNotificationAndCaseListingUpdate
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    additionalInfoCaseList,
    additionalInfoCaseListLoading,
    additionalInfoCount,
    additionalInfoChatCount,
    additionalInfoCaseUpdateCount,
  } = state.additionalInfoRequired;
  const { statesList, telemedicineCategoriesList } = state.entities;
  return {
    additionalInfoCaseList,
    additionalInfoCaseListLoading,
    statesList,
    telemedicineCategoriesList,
    additionalInfoCount,
    additionalInfoChatCount,
    additionalInfoCaseUpdateCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { getAdditionalInfoCaseList } = additionalInfoRequired.actions;
  const { setChatCaseId } = messageStore.actions;
  return {
    actions: bindActionCreators(
      {
        getAdditionalInfoCaseList,
        setChatCaseId,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdditionalInfoRequired);
