import React, { useState, useEffect, useContext } from "react";
import "./ConsultedCaseComponent.css";
import moment from "moment";
import { Table, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { TablePreferencesContext } from '../../context/TablePreferencesProvider';
import { history } from "../../helpers";
import { userConstants } from "../../constants";

import CaseSearchAndFilterComponent from "../CaseSearchAndFilterComponent/CaseSearchAndFilterComponent";
import { sorters } from '../../utils/sorters';

const ConsultedCaseComponent = (props) => {
  const {
    title,
    type,
    caseListLoading,
    consultedCaseList,
    totalCount,
    actions,
    telemedicineCategoriesList,
    statesList,
  } = props;
  const [searchValue, setSearchValue] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [caseListFilter, setCaseListFilter] = useState({});
  const { getTablePreferences, updateTablePreferences } = useContext(TablePreferencesContext);
  const { currentPage, tablePageSize, dateSortOrder } = getTablePreferences(type, { currentPage: 1, tablePageSize: 100, dateSortOrder: 'descend' });

  const columns = [
    {
      title: "Case No",
      dataIndex: "caseno",
      key: `${type}-caseno`,
      sorter: (a, b) => sorters.alphanumeric(a.caseId, b.caseId)
    },
    {
      title: "Patient Name",
      dataIndex: "name",
      key: `${type}-name`,
      sorter: (a, b) => sorters.alphanumeric(a.patientName, b.patientName)
    },
    {
      title: "Category Name",
      dataIndex: "categoryname",
      key: `${type}-categoryname`,
      sorter: (a, b) => sorters.alphanumeric(a.categoryName, b.categoryName)
    },
    {
      title: "Created Date",
      dataIndex: "createddate",
      key: `${type}-createddate`,
      sorter: (a, b) => sorters.dates(new Date(a.createddate), new Date(b.createddate))
    },
    {
      title: "Completed Date",
      dataIndex: 'completedAt',
      key: `${type}-completedAt`,
      sortOrder: dateSortOrder,
      sorter: (a, b) => sorters.dates(new Date(a.completedAt), new Date(b.completedAt))
    },
    {
      title: "Status",
      dataIndex: "statusDisplayName",
      key: `${type}-status`,
      sorter: (a, b) => sorters.alphanumeric(a.status, b.status)
    },
  ];

  const fetchConsultedCaseList = () => {
    const caseFilter = {};
    caseFilter["index"] = currentPage;
    caseFilter["case-count"] = tablePageSize;
    caseFilter["status"] = type;
    if (searchValue !== null) {
      caseFilter["search-term"] = searchValue;
    }
    if (caseListFilter.teleMedicineCategories) {
      caseFilter["telemedicine-category-id"] =
        caseListFilter.teleMedicineCategories;
    }
    if (caseListFilter.state) {
      caseFilter["state-id"] = caseListFilter.state;
    }
    if (caseListFilter.caseid) {
      caseFilter["case-id"] = caseListFilter.caseid;
    }
    if (caseListFilter.patientid) {
      caseFilter["patient-id"] = caseListFilter.patientid;
    }
    if (caseListFilter.type) {
      caseFilter["type"] = caseListFilter.type;
    }
    if (caseListFilter.createddate) {
      caseFilter["created-date"] =
        caseListFilter.createddate &&
        moment(caseListFilter.createddate).format("YYYY-MM-DD");
    }
    actions.getConsultedCaseList(type, caseFilter);
  };
  
  useEffect(() => { 
    fetchConsultedCaseList(currentPage, tablePageSize);
    // eslint-disable-next-line
  }, [
    searchValue,
    caseListFilter,
    currentPage,
    tablePageSize,
    type,
  ]);

  const handleIntialTableCount = () => {
    updateTablePreferences(type, { currentPage: 1, tablePageSize: 100 })
  };

  const handleOk = (filterData) => {
    setCaseListFilter(filterData);
    setShowModal(false);
    handleIntialTableCount();
  };

  const searchCase = (value) => {
    setSearchValue(value);
    handleIntialTableCount();
  };

  const handleCancel = () => {
    setCaseListFilter({});
    setShowModal(false);
    handleIntialTableCount();
  };

  const handleCancelCross = () => {
    setShowModal(false);
  };

  const onChangePage = (page) => {
    updateTablePreferences(type, { currentPage: page })
  };

  const onPageSizeChange = (_, size) => {
    updateTablePreferences(type, { currentPage: 1, tablePageSize: size })
  };

  return (
    <div className="consulted-case">
      <div className="waiting-room-case-list-part-heading">
        <h1>{title}</h1>
        <CaseSearchAndFilterComponent
          searchCase={searchCase}
          showModal={showModal}
          setShowModal={setShowModal}
          handleCancelCross={handleCancelCross}
          handleCancel={handleCancel}
          handleOk={handleOk}
          telemedicines={telemedicineCategoriesList}
          states={statesList}
          hideFields={['physician']}
        />
      </div>

      <div className="consulted-case-no-of-patient-list-data">
        <Spin
          size="large"
          spinning={caseListLoading}
          indicator={<LoadingOutlined spin />}
        >
          <div>
            <Table
              className="wating-room-table"
              columns={columns}
              dataSource={consultedCaseList}
              onChange={(pagination, filters, sorter) => { updateTablePreferences(type, { dateSortOrder: sorter.order || 'ascend' }) }}
              pagination={{
                total: totalCount,
                showTotal: () => `Total ${totalCount} cases`,
                current: currentPage,
                pageSize: tablePageSize,
                pageSizeOptions: ["5", "10", "20", "50", "100"],
                showSizeChanger: true,
                onChange: onChangePage,
                onShowSizeChange: onPageSizeChange,
              }}
              scroll={{ x: 900 }}
              size="large"
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    history.push(
                      `/dashboard/${userConstants.USER_PHYSICIAN}/caseview/${record.caseno}`,
                      { from: title === 'Completed Consults' ? 'consultedcase' : 'consultedcase-noRX' }
                    );
                  },
                };
              }}
            />
          </div>
        </Spin>
      </div>
    </div>
  );
};
export default ConsultedCaseComponent;
