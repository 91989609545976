/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Typography, Row, Col, Card, Spin, Button } from "antd";
import {
    VideoCameraOutlined,
    CalendarOutlined,
    AudioOutlined,
    LoadingOutlined,
} from "@ant-design/icons";

// import APIs
import SocketContext from "../../../context/socketContext";

const { Text, Title } = Typography;

const ScheduledCall = ({
    caseDetails,
    fetchCaseDetails,
    scheduleDetailsLoading,
    socket,
}) => {
    const { id: caseId } = useParams();
    let timeCheckInterval = null;
    
    const [joinWaitingRoom, setJoinWaitingRoom] = useState(false);

    // helper functions
    const formatDisplayTimes = (startTime, endTime) => { return `${moment(startTime).format("LLLL")} - ${moment(endTime).format("LT")}`; }
    const isPendingConsult = (caseStatus) => { return caseStatus === "pending audio consult" || caseStatus === "pending video consult"; }
    const hasStartAndEndTime = (appointment) => { return appointment && appointment.startTime && appointment.endTime; }
    const timeCheckForJoiningWaitingRoom = () => {
        const now = moment();

        if (caseDetails.consultations && caseDetails.consultations.startTime && caseDetails.consultations.endTime) {
            const startTime = moment(moment(caseDetails.consultations.startTime).format("LLLL"));
            const endTime = moment(moment(caseDetails.consultations.endTime).format("LLLL"));
            const startDiff = startTime.diff(now, "minutes");
            const endDiff = endTime.diff(now, "minutes");

            // if it is less than 15 minutes before start time or 15 minutes after end time, show waiting room button
            if (Math.abs(startDiff) <= 15 || Math.abs(endDiff) <= 15) { 
                setJoinWaitingRoom(true); 
            }
            return false;
        }
    };

    // handles
    const handleWaitingRoomJoiningFromCase = (caseId) => {
        clearInterval(timeCheckInterval);
        window.location.href = `/consultation/${caseId}`;
    };

    // hooks
    useEffect(() => {
        if (hasStartAndEndTime(caseDetails.consultations)) {
            timeCheckForJoiningWaitingRoom();
            // ? wouldn't this stack? use setTimeout instead?
            timeCheckInterval = setInterval(() => {
                timeCheckForJoiningWaitingRoom();
            }, 60000);
        }
    }, [caseDetails]);

    return (
        <div className="case-component-wrapper">
            <Spin
                size="large"
                spinning={scheduleDetailsLoading}
                indicator={<LoadingOutlined spin />}
            >
                {caseDetails.consultations == null ? (
                    <Row>
                        <Col span={22}>
                            <Title className="case-title-font-weight" level={2}>
                                Scheduled Consultation
                            </Title>
                            <Text strong>
                                It doesn't look like you have any scheduled appointments. Please check back again after you've scheduled your appointment.
                            </Text>
                        </Col>
                    </Row>
                ) : (
                    <>
                        <Row>
                            <Col span={22}>
                                <Title className="case-title-font-weight" level={2}>
                                    Scheduled Consultation
                                </Title>
                                {caseDetails.status === "waiting room" && (
                                    <Text strong>
                                        We have notified the physician that you are ready
                                        for the call and are currently waiting. The physician will be starting the
                                        call very soon. Please wait.
                                    </Text>
                                )}
                                {(!joinWaitingRoom && isPendingConsult(caseDetails.status)) && (
                                    <Text strong>
                                        Please login 10-15 minutes before your appointment start time.
                                    </Text>
                                )}
                                {joinWaitingRoom && caseDetails.status !== "waiting room" && caseDetails.status !== "pending prescription" && (
                                    <Text strong>
                                        If you are ready for your appointment, click "Go To Waiting Room" and the physician will be notified.
                                    </Text>
                                )}
                            </Col>
                        </Row>
                        <Row className="case-card-content-row-wrapper align-row-content">
                            <Col className="case-text-col-wrapper" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Card className="case-selection-card">
                                    <div className="card-content-wrapper">
                                        <div>
                                            <div>
                                                <CalendarOutlined className="case-icon" />
                                                <Text strong className="case-date-text">
                                                    {(caseDetails.consultations && caseDetails.consultations.startTime && caseDetails.consultations.endTime)
                                                        && formatDisplayTimes(caseDetails.consultations.startTime, caseDetails.consultations.endTime)
                                                    }
                                                </Text>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                            <Col className="case-icon-col-wrapper" xs={12} sm={12} md={12} lg={12} xl={12}>
                                {caseDetails.consultationType && caseDetails.consultationType === "audio" 
                                    ? (
                                        <AudioOutlined className="case-colored-icon font-size-144" />
                                    ) : (
                                        <VideoCameraOutlined className="case-colored-icon font-size-144" />
                                    )}
                            </Col>
                        </Row>
                        <Row justify="end">
                            {(joinWaitingRoom && isPendingConsult(caseDetails.status))
                                || caseDetails.status === "waiting room"
                                ? (
                                    <Col>
                                        <Button
                                            className="case-button-padding case-button-extra"
                                            type="primary"
                                            size="large"
                                            onClick={() => handleWaitingRoomJoiningFromCase(caseId)}
                                        >
                                            Go To Meeting Room
                                        </Button>
                                    </Col>
                                ) : (
                                    <span></span>
                                )}
                        </Row>
                    </>
                )}
            </Spin>
        </div>
    );
};

const ScheduledCallWithSocket = (props) => (
    <SocketContext.Consumer>
        {(socket) => <ScheduledCall {...props} socket={socket} />}
    </SocketContext.Consumer>
);

export default ScheduledCallWithSocket;
