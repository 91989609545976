import React, { forwardRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import config from '../../config';

export const AntTinyWrapper = forwardRef(({ value, onChange, disabled, placeholder, ...props }, ref) => (
  <Editor
    init={{
      toolbar: 'undo redo | styleselect | forecolor | bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent | link image | code | bullist | paste pastetext',
      menubar: false,
      statusbar: false,
      plugins: ['lists', 'paste'],
      placeholder: placeholder || '',
      paste_block_drop: true,
      paste_as_text: true

    }}
    disabled={disabled}
    value={value}
    onEditorChange={value => onChange?.(value)}
    onKeyDown={event => props.onKeyDown?.(event)}
    apiKey={config.apiKeys.tinyEditorKey}
    ref={ref}
    {...props}
  />
))