import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Avatar, Typography, Spin } from "antd";
import { UserOutlined, LoadingOutlined } from "@ant-design/icons";
import LogoIcon from "./../assets/icons/LogoIcon";

// import CSS
import "./layout.css";

//import component
import UserOptions from "./UserOptions";

//import functions
import API from "../axios/apiHelper";
import decodeToken from "../utils/decodeToken";

const { Header } = Layout;
const { Text } = Typography;

const MainHeader = () => {
  const [clientId, setClientId] = useState(null);
  const [clientLogoLoading, setClientLogoLoading] = useState(false);
  const [clientLogo, setClientLogo] = useState(null);

  const tokenVerification = () => {
    if (clientId === null) {
      console.log(
        "================================= client token ================================"
      );
      const token = localStorage.getItem("clientToken");
      if (token) {
        const decodedClientToken = decodeToken(token);
        const { id } = decodedClientToken;
        if (id) {
          setClientId(id);
        }
      }
    }
  };

  const fetchClientLogo = () => {
    if (clientId) {
      setClientLogoLoading(true);
      API.fetchLookUpDetails("client", clientId)
        .then((response) => {
          const { data } = response;
          if (data && data.success) {
            const { payload } = data;
            if (payload && payload.logoImage) {
              setClientLogo(payload.logoImage);
            }
          }
          setClientLogoLoading(false);
        })
        .catch((error) => {
          setClientLogoLoading(false);
        });
    } else {
      console.log("========== client id not found =====================");
    }
  };

  // eslint-disable-next-line
  useEffect(tokenVerification, []);

  useEffect(fetchClientLogo, [clientId]);

  return (
    <Header className="layout-header">
      <Row className="layout-header-row">
        {clientId ? (
          <Col className="layout-header-row-column-wrapper">
            <Spin
              spinning={clientLogoLoading}
              size="small"
              indicator={<LoadingOutlined />}
            >
              <div className="normal-line-height">
                {clientLogo ? (
                  <img
                    src={clientLogo}
                    className="layout-header-client-logo-image"
                    alt="logo"
                  ></img>
                ) : (
                  <Avatar
                    shape="square"
                    size="medium"
                    icon={<LoadingOutlined />}
                  />
                )}
              </div>
              <div className="normal-line-height layout-header-powered-section-wrapper">
                <Text className="layout-header-row-column-text" strong>
                  Powered By
                </Text>
                <LogoIcon width="92px" height="auto" />
              </div>
            </Spin>
          </Col>
        ) : (
          <Col className="layout-header-row-column">
            <LogoIcon width="172px" height="72px" />
          </Col>
        )}
        <Col className="layout-space-column" />
        <Col className="layout-header-user-option-col">
          <UserOptions>
            <Avatar className="layout-header-avatar" icon={<UserOutlined />} />
            <p className="layout-header-username">Case</p>
          </UserOptions>
        </Col>
      </Row>
    </Header>
  );
};

export default MainHeader;
