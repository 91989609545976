import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { Button, Typography, Spin } from "antd";
import { history } from "../../helpers";
import { userConstants  } from "../../constants";
import styled from 'styled-components'

import {
  FileAddOutlined,
  FileProtectOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";

import { 
  CaseStatusEnum, 
  RequestStatusEnum,
  CaseTagEnum, 
  PhysicianTagEnum, 
  PrescriptionStatusEnum,
} from "../../types/enums";

import CaseUpdateStatus from "./CaseUpdateStatus";
import isEmptyObject from "../../utils/isEmptyObject"

const StyledButton = styled(Button)`
  width: 280px;
  text-transform: capitalize;
`

const StyledSpin = styled(Spin)`
  & .ant-spin-dot-item {
    background-color: red;
  }
`

const { 
  PENDING_AUDIO_CONSULT,
  PENDING_VIDEO_CONSULT, 
  RX_RENEWAL, 
  RX_CHANGE,
  PENDING_PRESCRIPTION,
  ADDITIONAL_INFO_REQUIRED,
  INVALID_FACE_PHOTO,
  INVALID_PHOTO_ID,
  CONSULT_COMPLETE,
  REFER,
  PENDING_SCHEDULE,
  CASE_CREATED
} = CaseStatusEnum

const {
  PENDING_LAB_ORDER
} = RequestStatusEnum

const { Title, Text } = Typography;

const CasePerformActions = ({
  caseId,
  caseStatus,
  requestStatus,
  goToLabs,
  caseTags,
  fetchCaseDetails,
  caseCategory,
  caseClientId,
  lastUpdated,
  prescriptionStatus
}) => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [actionCaseStatus, setActionCaseStatus] = useState();
  const { physicianDetails } = useSelector(state => state.physicianDetails);

  const physicianTags = physicianDetails.tags?.map(tag => tag.toLowerCase())
  const physicianNeedsReview = physicianTags?.includes(PhysicianTagEnum.NEEDS_REVIEW)
  const physicianIsLead = physicianTags?.includes(PhysicianTagEnum.LEAD_PHYSICIAN)
  const isClearedCase = caseClientId.toString() === "62388f86a83ca843e4060e46"

  const onClickAction = (status) => {
    setModalVisibility(true);
    setActionCaseStatus(status);
  };

  if (physicianNeedsReview && caseStatus === REFER) return null

  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      padding: '20px',
      background: "#ffffff"
    }}>
          <Title className="sub-content-color case-form-action-title" level={4}>
            Requested Actions To Be Performed
          </Title>
          { prescriptionStatus === PrescriptionStatusEnum.ERRORS && <Text style={{ color: 'red'}}>This case has experienced an error in prescribing. Please continue to you next case until the admin team can resolve the issue.</Text>}
        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          gap: '20px',
          padding: '20px 0',
          }}>
        {
          ([
            PENDING_PRESCRIPTION,
            CASE_CREATED,
            PENDING_AUDIO_CONSULT,
            PENDING_VIDEO_CONSULT, 
            PENDING_AUDIO_CONSULT,
            PENDING_SCHEDULE,
            RX_RENEWAL
          ].includes(caseStatus) ||
          (caseStatus === REFER && physicianIsLead)) &&
              <StyledButton
                className="no-rx-button"
                size="large"
                icon={<FileAddOutlined />}
                disabled={prescriptionStatus === PrescriptionStatusEnum.ERRORS}
                onClick={() => onClickAction(CaseStatusEnum.ADDITIONAL_INFO_REQUIRED)}
              >
                { CaseStatusEnum.ADDITIONAL_INFO_REQUIRED }
              </StyledButton>
        }
        {
          ([
            PENDING_PRESCRIPTION, 
            CASE_CREATED,
            PENDING_VIDEO_CONSULT,
            PENDING_AUDIO_CONSULT,
            PENDING_SCHEDULE,
            ADDITIONAL_INFO_REQUIRED, 
            INVALID_FACE_PHOTO, 
            INVALID_PHOTO_ID,
            RX_RENEWAL
          ].includes(caseStatus) ||
          (caseStatus === REFER && physicianIsLead)) &&
              <StyledButton
                className='rx-button'
                size="large"
                // if case was reviewed and sent back to assigned physician,
                // physician cannot Rx until reviewing-physician's notes are
                // acknowledged. It is notable that once the REVIEWED tag is removed
                // the case will be removed from the 'for review' queue and only be accessible from
                // where case would normally reside when in its previous status. 
                disabled={caseTags?.includes(CaseTagEnum.REVIEWED) || prescriptionStatus === PrescriptionStatusEnum.ERRORS}
                icon={<ScheduleOutlined />}
                onClick={() => {
                  history.push(
                    `/dashboard/${userConstants.USER_PHYSICIAN}/prescription/${caseId}`
                  );
                }}
              >
                Prescribe Medicine
              </StyledButton>
        }
        {
          ([
            PENDING_PRESCRIPTION,
            CASE_CREATED,
            PENDING_VIDEO_CONSULT, 
            PENDING_AUDIO_CONSULT,
            PENDING_SCHEDULE,
            ADDITIONAL_INFO_REQUIRED,
            INVALID_FACE_PHOTO,
            INVALID_PHOTO_ID,
            RX_RENEWAL,
            RX_CHANGE
          ].includes(caseStatus) ||
          (caseStatus === REFER && physicianIsLead)) &&
              <StyledButton
                size="large"
                className="no-rx-button"
                disabled={isEmptyObject(physicianDetails) || prescriptionStatus === PrescriptionStatusEnum.ERRORS}
                icon={physicianDetails.tags && <FileProtectOutlined />}
                onClick={() => onClickAction(physicianNeedsReview && !isClearedCase ? CaseStatusEnum.REFER : CaseStatusEnum.CONSULT_COMPLETE_NO_RX )}
              >
                { !physicianDetails.tags && <StyledSpin />}
                { physicianDetails.tags && (physicianNeedsReview && !isClearedCase
                  ? CaseStatusEnum.REFER
                  : CaseStatusEnum.CONSULT_COMPLETE_NO_RX )}
              </StyledButton>
        }
        {
          ([
            RX_CHANGE,
            CONSULT_COMPLETE,
          ].includes(caseStatus) && (
            <StyledButton
              size="large"
              className='rx-button'
              icon={<ScheduleOutlined />}
              onClick={() => {
                history.push(
                  `/dashboard/${userConstants.USER_PHYSICIAN}/prescription/${caseId}`
                );
              }}
            >
              Update Prescription
            </StyledButton>
      ))
        }
        {
          [
            PENDING_LAB_ORDER,
          ].includes(requestStatus) && (
            <StyledButton
                className='rx-button'
                size="large"
                icon={<FileAddOutlined />}
                onClick={goToLabs}
              >
                Order Labs
              </StyledButton>
          ) 
        }
      </div>
      <CaseUpdateStatus
        modalVisibility={modalVisibility}
        actionCaseStatus={actionCaseStatus}
        setModalVisibility={setModalVisibility}
        caseCategory={caseCategory}
        caseId={caseId}
        lastUpdated={lastUpdated}
        fetchCaseDetails={fetchCaseDetails}
        physicianNeedsReview={physicianNeedsReview}
      />
    </div>
  );
};

export default CasePerformActions;
