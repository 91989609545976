import React, { useEffect, useState } from "react";
import { Card, Row, Col, Typography, Spin, Avatar } from "antd";
import { Redirect } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

// import CSS and logos
import "../LoginSelection.css";
import Logo from "../../../assets/images/svg/logo-one.png";
import Patientactive from "../../../assets/images/svg/PatientActive.svg";

// import components
import SafeConnection from "../../../components/SafeConnection";
import LoginCase from "./components/LoginCase";

// import common functions
import useQuery from "../../../utils/useQuery";
import decodeToken from "../../../utils/decodeToken";

//import functions
import API from "../../../axios/apiHelper";

const { Text } = Typography;

const CaseLogin = () => {
  let query = useQuery();
  const [clientId, setClientId] = useState(null);
  const [clientLogoLoading, setClientLogoLoading] = useState(false);
  const [clientLogo, setClientLogo] = useState(null);

  const tokenVerification = () => {
    const token = query.get("token");
    if (token) {
      localStorage.setItem("clientToken", token);
      const decodedClientToken = decodeToken(token);
      const { id } = decodedClientToken;
      if (id) {
        setClientId(id);
      }
    }
  };

  const fetchClientLogo = () => {
    if (clientId) {
      setClientLogoLoading(true);
      API.fetchLookUpDetails("client", clientId)
        .then((response) => {
          const { data } = response;
          if (data && data.success) {
            const { payload } = data;
            if (payload && payload.logoImage) {
              setClientLogo(payload.logoImage);
            }
          }
          setClientLogoLoading(false);
        })
        .catch((error) => {
          setClientLogoLoading(false);
        });
    } else {
      console.log("========== client id not found =====================");
    }
  };

  // eslint-disable-next-line
  useEffect(tokenVerification, []);

  useEffect(fetchClientLogo, [clientId]);

  return window.localStorage.getItem("token") !== null &&
    window.localStorage.getItem("type") === "physician" ? (
    <Redirect to="/dashboard" />
  ) : window.localStorage.getItem("token") !== null &&
    window.localStorage.getItem("caseId") !== null ? (
    <Redirect
    //   to={`/case/scheduling/${window.localStorage.getItem("caseId")}`}
        to={`/case/scheduled-call/${window.localStorage.getItem("caseId")}`}
    />
  ) : (
    <div className="loginselection-part">
      <Card className="login-selection-part-card">
        <Row className="login-section-row-wrapper" gutter={[16, 16]}>
          <Col
            className="wrapper-text-align"
            xl={12}
            lg={12}
            md={24}
            sm={24}
            xs={24}
          >
            <img
              src={Patientactive}
              alt="logo"
              className="logo-active-inactive-selection"
            ></img>
            <p>
              <b>I'm a</b>
              <span style={{ color: "#7BAD7E" }}>
                <b>{` Patient`}</b>
              </span>
            </p>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <LoginCase />
            <hr></hr>
            <SafeConnection />
          </Col>
        </Row>
      </Card>

      <div className="login-selection-part-one">
        {clientId ? (
          <>
            <Spin
              spinning={clientLogoLoading}
              size="small"
              indicator={<LoadingOutlined />}
            >
              {clientLogo ? (
                <img
                  src={clientLogo}
                  alt="logo"
                  className="login-selection-part-one-image"
                />
              ) : (
                <Avatar
                  shape="square"
                  size="large"
                  icon={<LoadingOutlined />}
                />
              )}
              <div>
                <div>
                  <Text className="login-selection-part-one-text" strong>
                    Powered By
                  </Text>
                </div>
                <img
                  src={Logo}
                  alt="logo"
                  className="login-selection-part-one-sub-image"
                />
              </div>
            </Spin>
          </>
        ) : (
          <img
            src={Logo}
            alt="logo"
            className="login-selection-part-one-image"
          ></img>
        )}
      </div>
      <div className="login-selection-part-two" />
    </div>
  );
};

export default CaseLogin;
