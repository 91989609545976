/* eslint-disable react-hooks/exhaustive-deps */
//import packages
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Row, Col, Typography, message, Tooltip, Popover, Timeline, Tag } from "antd";
import { EyeOutlined, HistoryOutlined, WarningOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import CaseRequestedMedicines from "./CaseRequestedMedicines";
import CaseMedicalInformationEdit from "./CaseMedicalInformatiomEdit";
import { CaseVisitNotes } from "./CaseVisitNotes";
import { PharmacyDetailsComponent } from "../../components/PharmacyDetailsComponent/PharmacyDetailsComponent";
import CaseTagsComponent from "../../components/CaseTagsComponent"
import { userConstants } from "../../constants";
import articles from "../../redux/articles";
import moment from "moment";
import API from "../../axios/apiHelper";
import HTMLReactParser from 'html-react-parser';
import styled from "styled-components";
import { useAllergies } from "./hooks/useAllergies";

import { 
  CaseStatusEnum, 
  PrescriptionStatusEnum, 
  CaseStatusColorCodeEnum,
  RequestStatusEnum 
} from "../../types/enums"

const { Text, Title } = Typography;

const StyledReasonNote = styled.section`
  height: 200px;
  padding: 15px;
  margin: -10px 0px 30px;
  overflow-y: scroll;
  border: 1px solid lightgrey;
`

const StatusHistoryIcon = styled(HistoryOutlined)`
  margin: 0 5px;
  & :hover {
    cursor: pointer;
  }
`

const StyledTimeline = styled(Timeline)`
  width: 500px;
  padding-top: 10px !important;
  & .ant-timeline-item-tail {
    border-left: 2px solid rgba(0,0,0,.15) ;
  }

  & .ant-timeline-item:last-of-type {
    padding-bottom: 0;
  }
`

const genderDict = {
  m: 'Male',
  M: 'Male',
  f: 'Female',
  F: 'Female'
}

const StatusHistoryPopover = props => {
  let { statusHistory } = props
  return (
    <StyledTimeline mode='left'>
      { statusHistory?.length && statusHistory.map(item => (
        <Timeline.Item
          color={CaseStatusColorCodeEnum[item.status?.toLowerCase()] || '#000'}
          style={{ textTransform: 'capitalize'}}
          label={moment(item.createdAt).format('MMM Do YYYY h:mma')}>
            <p>
              <span style={{ fontWeight: 'bold'}}>{item.status}</span>
              <br />
              { item.physicianName && (
                <span style={{ fontSize: '.8rem'}}>Assigned to: {item.physicianName}</span>
              )}
              { item.reviewedBy && (
                <>
                  <br />
                  <span style={{ fontSize: '.8rem'}}>Reviewed by: {item.reviewedBy}</span>
                </> 
              )}
            </p>
        </Timeline.Item>
      ))}
    </StyledTimeline>
  )
}

const PatientAllergies = ({ allergies }) => {

  if (!allergies) return <>Unavailable</>
  if (!allergies.length) return <>None</>

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {allergies.map((allergy) => (
        <Tag 
          key={allergy.name} 
          size="large"
          style={{ 
            margin: '4px', 
            backgroundColor: 'rgb(123, 173, 126)', 
            color: '#fff',
            fontSize: '1.05rem',
            padding: '3px 8px'
          }}>
          {allergy.name}
        </Tag>
      ))}
    </div>
  )
}

const CaseDetails = ({
  caseDetails,
  physicianDetails,
  fetchCaseDetails,
  chatView,
  setCaseCategoryId,
  goToLabs
}) => {
    const [clientLogo, setClientLogo] = useState();
      
    const { patientAllergies, getPatientAllergies } = useAllergies(caseDetails?.patientId)
    const mostRecentRequest = caseDetails.requests.at(-1)

    const fetchClientLogo = () => {
        if (caseDetails?.clientId) {
          API.fetchLookUpDetails("client", caseDetails?.clientId)
            .then((response) => {
              const { data } = response;
              if (data && data.success) {
                const { payload } = data;
                if (payload && payload.logoImage) {
                  setClientLogo(payload.logoImage);
                }
              }
            })
        }
    };

    useEffect(() => {
        fetchClientLogo();
    }, []);

  message.config({
    duration: 3,
  });

  const history = useHistory();

  const navigateToArticle = (categoryId) => {
    history.push(`/dashboard/${userConstants.USER_PHYSICIAN}/articles`);
    setCaseCategoryId(categoryId);
  };


  return (
    <>
      <Form
        name="case-details-consultation"
        className="case-detailed-view-part"
      >
        <Row className="case-patient-details">
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Case Id">
              <Text className="sub-content-color" strong>
                {caseDetails?.id || ""}
              </Text>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Patient Name">
              <Text className="sub-content-color" strong>
                {`${caseDetails?.patient.firstName} ${caseDetails?.patient.lastName} (${genderDict[caseDetails?.patient.gender] || 'None Specified'})`}
              </Text>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Telemedicine Category">
              <div className="case-details-telemedicine-category">
                <Text className="sub-content-color" strong>
                  {caseDetails?.category?.name || ""}
                </Text>
                <Tooltip placement="topLeft" title="View Related Articles">
                  <EyeOutlined
                    style={{ marginLeft: "15px", fontSize: "20px" }}
                    onClick={() => navigateToArticle(caseDetails?.categoryId)}
                  />
                </Tooltip>
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Consultation Type">
              <Text className="sub-content-color" strong>
                {caseDetails?.consultationType?.toUpperCase() || ""}
              </Text>
            </Form.Item>
          </Col>
          {caseDetails?.consultations && (
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                label={
                  [
                    CaseStatusEnum.PENDING_AUDIO_CONSULT,
                    CaseStatusEnum.PENDING_VIDEO_CONSULT,
                    CaseStatusEnum.WAITING_ROOM
                  ].includes(caseDetails?.status)
                    ? "Scheduled Consultation"
                    : "Consulted At"
                }
              >
                <Text className="sub-content-color" strong>
                  {caseDetails?.consultations.startTime &&
                    caseDetails.consultations?.endTime
                    ? `${moment(caseDetails?.consultations?.startTime).format(
                      "LLLL"
                    )} - ${moment(caseDetails?.consultations?.endTime).format(
                      "LT"
                    )}`
                    : ""}
                </Text>
              </Form.Item>
            </Col>
          )}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Case Status">
              <Text className="sub-content-color" strong>
                {caseDetails?.status?.toUpperCase() || ""}
              </Text>
              <Popover content={<StatusHistoryPopover statusHistory={caseDetails?.statusHistory}/>} title={<div style={{ textAlign: 'center', paddingBottom: '5px', borderBottom: '1px solid rgba(0,0,0,.2)'}}>Case Status History</div>}>
                <StatusHistoryIcon/>
              </Popover>
              { caseDetails.prescriptionStatus === PrescriptionStatusEnum.ERRORS && (
                  <Tooltip placement="topLeft" title="Prescription error. See physician notes.">
                    <WarningOutlined style={{ fontSize: "20px", color: "red" }} />
                  </Tooltip>
              )}
            </Form.Item>
          </Col>
          {
            !!caseDetails.consultations && (
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item label='Consultation Status'>
                  <Text className="sub-content-color" strong>
                    {caseDetails?.consultations?.consultationStatus?.toUpperCase()}
                  </Text>
                </Form.Item>
              </Col>
            )}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="State">
              <Text className="sub-content-color" strong>
                {caseDetails?.state}
              </Text>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Brand">
              {clientLogo ? (
                <img
                  src={clientLogo}
                  alt="Brand"
                  className="case-view-client-logo"
                />
              ) : (
                <Text strong>
                  Brand Logo Unavailable
                </Text>
              )}

            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Reason Note" />
              <StyledReasonNote>
                {caseDetails?.status === "rx change" 
                  ? HTMLReactParser(caseDetails?.rxChangeRequest?.rxChangeReason || 'No reason note available')
                  : HTMLReactParser(caseDetails?.reason || 'No reason note available')
                }
              </StyledReasonNote>
          </Col>
          <Col span={10}>
            <CaseTagsComponent
              currentTags={caseDetails?.tags || []}
              caseId={caseDetails?.id}
            />
          </Col>
        </Row>

        <hr/>
        <CaseVisitNotes caseDetails={caseDetails} />
        <hr/>

        <Row className="case-details-row-wrapper">
          <Col span={!chatView ? 12 : 24}>
            <Title className="sub-content-color" level={4}>
              Medical Information
            </Title>
          </Col>

          {!chatView && (
            <Col span={12}>
              <CaseMedicalInformationEdit
                getPatientAllergies={getPatientAllergies}
                patientAllergies={patientAllergies}
                caseDetails={caseDetails}
                fetchCaseDetails={fetchCaseDetails}
              />
            </Col>
          )}

          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item label="Blood Pressure">
              <Text className="sub-content-color" strong>
                { caseDetails?.bloodPressure ||  "" }
              </Text>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item label="Medications">
              <Text className="sub-content-color" strong>
                {(caseDetails?.medications || '').split(',').map(part => part.trim()).join(', ')}
              </Text>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item label="Patient-Provided Description of Allergies">
              <Text className="sub-content-color" strong>
                {caseDetails?.allergies || ""}
              </Text>
            </Form.Item>
            <Form.Item label="Allergies on Record">
                <PatientAllergies allergies={patientAllergies} />
            </Form.Item>
          </Col>
        </Row>

        <hr></hr>

        <CaseRequestedMedicines caseDetails={caseDetails} />

        <hr></hr>

        <PharmacyDetailsComponent
          pharmacyId={caseDetails?.pharmacyId}
          patientId={caseDetails?.patientId}
          title={"Pharmacy"}
          contentSize={"small"}
          primaryColoredTitle={true}
          copyDetails={false}
          splitFormDetails={true}
          className="case-details-row-wrapper"
        />
      </Form>
    </>
  );
};

const mapDispatchToProps = {
  setCaseCategoryId: articles.actions.setCaseCategoryId,
};

const mapStateToProps = state => {
  const {
    physicianDetails
  } = state.physicianDetails

  return { physicianDetails }
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseDetails);
