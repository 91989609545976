import React, { useState } from "react";
import { Card, InputNumber, Button, Row, Col, Typography, message } from "antd";
import Logo from "../../../../assets/images/svg/logo-one.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import authentication from "../../../../redux/authentication";
import API from "../../../../axios/apiHelper";

const { Text } = Typography;

const VerificationOTP = (props) => {
  const { actions } = props;
  const [otpValue, setOtpValue] = useState();
  const [loading, setLoading] = useState(false);

  function resendOtpVerification() {
    const data = {
      _id: window.localStorage.getItem("_id"),
      type: "physician",
    };
    API.resendOtpCode(data)
      .then((res) => {
        const { data } = res;
        if (data && data.payload) {
          message.success(data.payload.sendVia);
        } else {
          message.error("Unable to send OTP");
        }
      })
      .catch(() => {
        message.error("Unable to send OTP");
      });
  }

  function onChange(value) {
    setOtpValue(value);
  }

  function onVerifyOtp() {
    const data = {
      otp: otpValue,
      _id: window.localStorage.getItem("_id"),
      type: "physician",
    };
    setLoading(true);
    actions.verfiyAuthenticationOtp(data, verifyComplete);
  }

  const verifyComplete = () => {
    setLoading(false);
  };
  return (
    <div className="verification-wrapper">
      <Card style={{ width: 320 }}>
        <img src={Logo} alt="logo" className="verification-image-logo" />

        <Row gutter={24} className="verification-row">
          <Col span={24}>
            <Text className="primary-font-color">
              <b>Enter the One Time Password (OTP) sent to your E-mail or Phone.</b>
            </Text>
          </Col>

          <Col span={24}>
            <InputNumber
              onChange={onChange}
              placeholder="Enter One Time Password"
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={24}>
            <Button
              type="primary"
              style={{ width: "100%" }}
              loading={loading}
              onClick={() => onVerifyOtp()}
            >
              Verify OTP
            </Button>
          </Col>
          <Col span={24}>
            <Text>
              <b>OTP is valid for 10 minutes</b>
            </Text>
          </Col>
          <Col span={24}>
            <Text
              onClick={() => resendOtpVerification()}
              style={{ cursor: "pointer", color: "#7bad7e" }}
            >
              <b>Resend OTP</b>
            </Text>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  const { verfiyAuthenticationOtp } = authentication.actions;
  return {
    actions: bindActionCreators(
      {
        verfiyAuthenticationOtp,
      },
      dispatch
    ),
  };
};

export default connect(null, mapDispatchToProps)(VerificationOTP);
