import React from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Input, Button } from 'antd'

import { UserDetails } from '../../../components/UserDetails'
import { Prescription } from './Prescripton'

import { useProviderContext } from '../../../context/ProviderContext'
import { usePatientContext } from '../../../context/PatientContext';

import prescribeMedicine from '../../../../../redux/prescribeMedicine';

const ReviewPrescriptions = ({ 
    pharmacy, 
    prescriptions, 
    hasControlledSubstance, 
    providerPin,
    tfaCode,
    useTFA,
    providerAuthenticated,
    physicianDetails,
    actions 
}) => {
    const { provider }= useProviderContext()
    const { patient } = usePatientContext()

    const prescriptionsArray = !!prescriptions && Object.values(prescriptions)
    let mappedPharmacy

    const handleReadyToSign = (_id, readyToSign) => {
        actions.saveQueuedPrescription({ _id, readyToSign })
        actions.evaluateReadyToComplete(physicianDetails)
    }

    const handleSetProviderPin = ({ target }) => {
        actions.setProviderPin(target.value)
        actions.evaluateReadyToComplete(physicianDetails)
    }

    const handleSetOTPCode = ({ target }) => {
        actions.setProviderOTPCode(target.value)
        actions.evaluateReadyToComplete(physicianDetails)
    }

    const handleUseTFA = () => {
        actions.setUseTFA(!useTFA)
        useTFA && actions.setProviderOTPCode(null)
        actions.evaluateReadyToComplete(physicianDetails)
    }

    if (pharmacy) {
        const { Address1, City, State, ZipCode, PrimaryPhone } = pharmacy
        mappedPharmacy = {
            address1: Address1,
            city: City,
            state: State,
            zipCode: ZipCode,
            phone: PrimaryPhone
        }
    }   

    return (
        <div style={{ width: '100%'}}>
            <div style={{ marginBottom: '30px', display: 'flex', flexDirection: 'column', background: '#FAFBFC', padding: '10px 0', gap: '10px' }}>
                { patient && <UserDetails type='patient' vertical name={`${patient?.firstName} ${patient.lastName}`} user={patient} style={{ minWidth: '33.33%' }}/> }
                <hr style={{ height: '1px', width: '95%', backgroundColor: 'rgba(0,0,0,.2', border: 'none'}}/>
                { provider && <UserDetails type='provider' name={provider?.username} user={provider} style={{ minWidth: '33.33%' }}/> }
                <hr style={{ height: '1px', width: '95%', backgroundColor: 'rgba(0,0,0,.2', border: 'none'}}/>
                { mappedPharmacy && <UserDetails type='pharmacy' name={pharmacy?.name} user={mappedPharmacy} style={{ minWidth: '33.33%' }}/> }
            </div>
            { prescriptionsArray?.map((prescription, i) => {
                return <div key={prescription._id}>
                    <Prescription prescription={prescription} i={i} handleReadyToSign={handleReadyToSign}/>
                    { i !== prescriptions.length - 1 && <hr style={{ margin: '30px 0', borderTop: '#FAFBFC'}}/> }
                </div>
            })}
            {
                physicianDetails.pinSet && (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px'}}>

                            { hasControlledSubstance && (
                                <div style={{ width: '70%', margin: '10px', background: '#FAFBFC', padding: '10px 20px' }}>
                                    By completing the two-factor authentication protocol at this time, you are legally signing the 
                                    prescription(s) and authorizing the transmission of the above information to the pharmacy for 
                                    dispensing. The two-factor authentication protocol may only be completed by the practitioner whose 
                                    name and DEA registration number appear above.
                                </div> 
                            )}

                            <div>
                                PIN: 
                                <Input
                                    style={{ width: '75px', marginLeft: '10px' }}
                                    maxLength={6}
                                    type="password"
                                    size="small"
                                    label="PIN"
                                    value={providerPin}
                                    onChange={handleSetProviderPin}
                                />
                            </div>
                        
                            { hasControlledSubstance && providerAuthenticated && <div>Authenticated</div> }
                            { hasControlledSubstance && !providerAuthenticated && (
                                <div style={{ width: '400px', display: 'flex', flexDirection: 'column'}}>
                                    <p>Please input your two-factor authentication security code from your DUO mobile application OR send a push notification to DUO</p>
                                    <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                                        { !useTFA && (
                                            <span>
                                                TFA Code:
                                                <Input
                                                    style={{ width: '75px', margin: '0 10px' }}
                                                    maxLength={6}
                                                    size="small"
                                                    label="TFA"
                                                    value={tfaCode}
                                                    onChange={handleSetOTPCode}
                                                />
                                                or
                                            </span>
                                        )}   
                                        <Button type={useTFA ? 'primary' : null} size="small" onClick={handleUseTFA}>{`Request${useTFA ? 'ing' : ''} DUO push`}</Button>
                                    </div>
                                </div>
                            )}  
                        </div>
                        <hr style={{ margin: '30px 0', borderTop: '#FAFBFC'}}/>
                    </>
                )}
        </div>
    )   
}

const mapStateToProps = (state) => {
    const { 
        prescriptions,
        pharmacy,
        providerPin,
        otpCode,
        useTFA,
        providerAuthenticated
     } = state.prescribeMedicine;
     const {
         physicianDetails
     } = state.physicianDetails
    return { 
        prescriptions,
        pharmacy,
        providerPin,
        otpCode,
        useTFA,
        providerAuthenticated,
        physicianDetails
     };
  };

  const mapDispatchToProps = (dispatch) => {
    const { 
        saveQueuedPrescription,
        setProviderPin,
        setProviderOTPCode,
        setUseTFA,
        evaluateReadyToComplete
    } = prescribeMedicine.actions;
    return { actions: bindActionCreators({ 
        saveQueuedPrescription,
        setProviderPin,
        setProviderOTPCode,
        setUseTFA,
        evaluateReadyToComplete
    }, dispatch) };
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReviewPrescriptions);