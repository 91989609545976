import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import TicketApi from "../../axios/TicketApi";

// import components
import AdminPhysicianChatListingComponent from "../../components/AdminPhysicianChatListingComponent/AdminPhysicianChatListingComponent";
import MessageHeaderComponent from "../../components/MessageHeaderComponent/MessageHeaderComponent";
import caseTickets from "../../redux/caseTickets";
import API from "../../axios/physician/PhysicianApi";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const CasePhysicianChat = ({ 
  caseDetails, 
  actions,
  enableCaseDetailsView 
}) => {
  const [messageListLoading, setMessageListLoading] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const [chatRoomId, setChatRoomId] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [messageSend, setMessageSend] = useState(false);

  const userEmail = window.localStorage.getItem("useremail");

  const fetchMessageList = (params) => {
    setMessageListLoading(true);
    TicketApi.getTicketChat(params)
      .then((res) => {
        const { data } = res;
        if (data.success && data.payload) {
          const { payload } = data;
          const messageListData = payload;
          const dataMessageList = messageList.data;
          const messagesPayload = payload.data.reverse();

          if (dataMessageList) {
            messageListData.data = messagesPayload.concat(dataMessageList);
          }
          setMessageList(messageListData);
        }
        setMessageListLoading(false);
      })
      .catch(() => {
        setMessageList([]);
        setMessageListLoading(false);
      });
  };

  function getCaseTicketClearNotification(chatRoomId) {
    const params = { ticketId: chatRoomId };
    API.getCaseTicketClearNotifications(params)
      .then((res) => {
        const { data } = res;
        if (data.success) {
          actions.getCaseTicketNotificationCount();
        } else {
          console.log("Unable to clear Notification Count");
        }
      })
      .catch((error) => {
        console.log("Unable to clear Notification Count");
      });
  }

  // handling message viewing
  const handleMessageViewing = () => {
    console.log("message viewed");
  };

  // handling message sending
  const handleMessageSending = (data) => {
    var messageData = {};

    if (messageList) {
      if (messageList.length === 0) {
        messageData = {
          caseId: data.chatRoomId,
          message: data.message,
        };
      } else {
        messageData = {
          ticketId: data.chatRoomId,
          message: data.message,
        };
      }
    }

    TicketApi.sendChatMessage(messageData)
      .then((res) => {
        if (res.data.success) {
          setMessageList([]);
          setMessageSend(true);
          setPageNumber(1);
          actions.getCaseTicketNotificationCount()
        }
      })
      .catch((error) => {
        const { response } = error;
        if (response) {
          if (response && response.data.message) {
            message.error(response.data.message);
          } else {
            message.error("unable to send Message");
          }
        } else {
          message.error("Unable to send message");
        }
        console.log(
          "=================== Chat Room Error :: Add messages =====================",
          error
        );
      });
  };

  const clearTicketNotifications = () => {
    getCaseTicketClearNotification(chatRoomId);
  };

  useEffect(() => {
    if (messageList) {
      if (messageList.length === 0) {
        setChatRoomId(caseDetails.caseId);
      } else {
        const ticketMessage = messageList.data[0];
        setChatRoomId(ticketMessage.ticketId);
      }
    }// eslint-disable-next-line
  }, [messageList]);

  useEffect(() => {
    setPageNumber(1);
    if (caseDetails) {
      const params = {
        caseId: caseDetails.caseId,
        page: pageNumber,
        limit: 10,
      };
      fetchMessageList(params);
    }// eslint-disable-next-line
  }, [caseDetails]);

  useEffect(() => {
    if (pageNumber === 1 && messageList.length === 0 && messageSend) {
      const params = {
        caseId: caseDetails.caseId,
        page: 1,
        limit: 10,
      };
      setMessageSend(false);
      if (caseDetails.caseId) {
        fetchMessageList(params);
      } else {
        setMessageList([]);
      }
    }// eslint-disable-next-line
  }, [messageList, pageNumber, messageSend]);

  const handleLoadMore = () => {
    const params = {
      caseId: caseDetails.caseId,
      page: pageNumber + 1,
      limit: 10,
    };
    setPageNumber(pageNumber + 1);
    fetchMessageList(params);
  };

  const handleHeaderOptionsClick = (option) => {
    enableCaseDetailsView(caseDetails.caseId);
  };

  const subTitle = "Use this feature to chat with the Clinical and Admin support staff for technical issues or Clinical questions related to this case."

  return (
    <>
      <Spin
        size="large"
        wrapperClassName="height-hundred"
        spinning={messageListLoading}
        indicator={<LoadingOutlined spin />}
      >
        <MessageHeaderComponent
          title={caseDetails.name}
          subTitle={subTitle}
          onOptionsClick={handleHeaderOptionsClick}
        />
        <AdminPhysicianChatListingComponent
          roomId={chatRoomId && chatRoomId}
          authorId={userEmail}
          messages={messageList}
          handleLoadMore={handleLoadMore}
          pageNumber={pageNumber}
          allowImageUpload
          sendMessage={handleMessageSending}
          handleMessageView={handleMessageViewing}
          clearTicketNotifications={clearTicketNotifications}
          className="message-list-height-background"
        />
      </Spin>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  const { getCaseTicketNotificationCount } = caseTickets.actions;

  return {
    actions: bindActionCreators(
      {
        getCaseTicketNotificationCount,
      },
      dispatch
    ),
  };
};

export default connect(null, mapDispatchToProps)(CasePhysicianChat);
