import React from 'react'
import dayjs from 'dayjs'

import { Typography } from 'antd'

import { PrescriptionItem } from './PrescriptionItem'

export const RequestedMedication = ({
    prescription,
    isFirstMedication
}) => {
    const { requestedMedication } = prescription

    if (!requestedMedication) return <div style={{ width: '40%' }}></div>
    if (!requestedMedication.drugName) return <div style={{ width: '40%' }}></div>

    const {
        refills,
        drugName,
        dosage,
        daysSupply,
        dispense,
        ndc,
        pillCount,
    } = requestedMedication

    return (
        <div style={{ width: '100%', padding: '75px 0 0 60px' }}>
            <Typography.Title strong level={4} style={{ marginBottom: '55px' }}>{'Requested Medicine'}</Typography.Title>
            <div style={{
                height: '400px',
                marginTop: '20px',
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridRowGap: '55px',
            }}>
                <PrescriptionItem vertical label="Medication" value={drugName} span={2} />
                {dosage && <PrescriptionItem vertical label="Strength" value={dosage} />}
                <PrescriptionItem vertical label="Effective Date" value={dayjs().format('MM/DD/YYYY')} />
                {pillCount && <PrescriptionItem vertical label="Quantity" value={pillCount} />}
                {dispense && <PrescriptionItem vertical label="Dispense Unit" value={dispense} />}
                <PrescriptionItem vertical label="Refills" value={refills || 0} />
                {daysSupply && <PrescriptionItem vertical label="Days Supply" value={daysSupply} />}
                <PrescriptionItem vertical label="NDC" value={ndc} />
            </div>
        </div>

    )
}