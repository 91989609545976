/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";
import {
  AudioMutedOutlined,
  AudioOutlined,
  PhoneFilled,
  UserOutlined,
  FileTextOutlined
} from "@ant-design/icons";
import "./PhoneCall.css";
import PhysicianApi from "../../axios/physician/PhysicianApi";
import CaseRegisterApi from "../../axios/case/CaseRegisterApi";
import { useParams, useHistory } from "react-router-dom";
import CaseDetailsDraw from '../Messages/CaseDetailsDraw';
const { Device } = require("twilio-client");

const PhoneCall = () => {
  const history = useHistory();
  const { id: caseId } = useParams();
  const [muted, setMuted] = useState(false);
  const [log, setLog] = useState("connecting");
  const [onPhone, setOnPhone] = useState(false);
  const [currentNumber, setCurrentNumber] = useState();
  const [callToken, setCallToken] = useState();
  const [caseDetails, setCaseDetails] = useState({}); 
  const [caseDetailsDrawerOpen, setCaseDetailsDrawerOpen] = useState(false);
  const fetchConnectionToken = () => {
    PhysicianApi.getPhoneCallToken()
      .then((res) => {
        const { data } = res;
        if (data) {
          setCallToken(data.token);
        }
      })
      .catch(() => {
        console.log("error");
      });
  };
  const fetchCaseDetails = () => {
    CaseRegisterApi.getCaseDetails(caseId)
      .then((res) => {
        const { data } = res;
        if (data && data.success && data.payload && data.payload[0]) {
          const { payload } = data;
          setCaseDetails(payload[0]);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchConnectionToken();
    fetchCaseDetails();
  }, []);

  useEffect(() => {
    if (callToken && caseDetails) {
      Device.setup(callToken);
      // Configure event handlers for Twilio Device
      Device.disconnect(function () {
        setOnPhone(false);
        setLog("Call ended");
      });

      Device.ready(function () {
        setLog("Connected");
      });
      setCurrentNumber(caseDetails.patient&&caseDetails.patient.phone);
    }
  }, [callToken, caseDetails]);

  useEffect(() => {
    if (currentNumber) {
      handleOnCall();
    }
  }, [currentNumber]);

  const handleOnCall = () => {
    if (!onPhone) {
      setMuted(false);
      setOnPhone(true);
      // make outbound call with current number
      var n = currentNumber;
      Device.connect({ number: n });
      setLog("Calling..."+n);
    } else {
      // hang up call in progress
      Device.disconnectAll();
      history.push(`/dashboard/physician/caseview/${caseId}`);
    }
  };

  const handleHungUp = () => {
    // hang up call in progress
    Device.disconnectAll();
    history.push(`/dashboard/physician/caseview/${caseId}`);
  };

  const handleMuteCall = () => {
    var muteFlag = !muted;
    setMuted(muteFlag);
    Device.activeConnection().mute(muteFlag);
  };

  return (
    <div className="phone-call">
      <div className="phone-call-status">
        <h1>Call Status : {log}</h1>
      </div>
      <div className="phone-call-text-part">
        <div className="phone-call-patient-icon">
          <UserOutlined />
        </div>
        {caseDetails.patient && (
          <h2>
            Patient name :{" "}
            {caseDetails.patient &&
              caseDetails.patient.firstName +
                " " +
                caseDetails.patient.lastName}
          </h2>
        )}
      </div>
      <div className="phone-call-control-part">
        <Tooltip title="Hang Up">
          <PhoneFilled
            className="room-leave-room"
            rotate={225}
            onClick={() => handleHungUp()}
          />
        </Tooltip>

        {muted ? (
          <Tooltip title="Unmute">
            <AudioMutedOutlined
              className="room-audio-cut"
              onClick={() => handleMuteCall()}
            />
          </Tooltip>
        ) : (
          <Tooltip title="Mute">
            <AudioOutlined
              className="room-audio-open"
              onClick={() => handleMuteCall()}
            />
          </Tooltip>
        )}

        <Tooltip title="Case Details">
          <FileTextOutlined
            className="room-case-details"
            onClick={() => setCaseDetailsDrawerOpen(true)}
          />
        </Tooltip>
      </div>
      <CaseDetailsDraw caseId={caseId} caseDetailsDrawCollapse={caseDetailsDrawerOpen} handleCaseDetailsDrawClose={() => setCaseDetailsDrawerOpen(false)} />
    </div>
  );
};

export default PhoneCall;
