import "../AdditionalInfoRequired/AdditionalInfoRequired.css";

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Spin, Select, message, Modal, Typography, Tooltip } from "antd";
import moment from 'moment-timezone'
import styled, { keyframes } from 'styled-components'

import { 
  LoadingOutlined, 
  ReloadOutlined, 
  PhoneOutlined, 
  MedicineBoxOutlined, 
  SwapOutlined, 
  VideoCameraOutlined, 
  ExperimentOutlined 
} from "@ant-design/icons";


import { history } from "../../helpers";
import { userConstants } from "../../constants";
import { CaseStatusEnum, PhysicianTagEnum } from "../../types/enums"
import { dateTimeFormatter } from "../../utils/dateFormatter";

import caseQueue from "../../redux/caseQueue"

import PhysicianApi from "../../axios/physician/PhysicianApi";

const { Text } = Typography;

const StyledTable = styled(Table)`
  .animate * {
    animation-name: ${keyframes`
    0% { background: #7bad7b; }
    100% { background: #fff; }
  `};
    animation-duration: 2s;
    animation-iteration-count: 1;
  }
`

const StyledSpin = styled(Spin)`
  margin-left: 14px;
  .ant-spin-dot {
    font-size: 25px;
  }
  i {
    background-color: #7bad7b;
    height: 10px;
    width: 10px;
  }
`

const StyledSmallSpin = styled(Spin)`
  i {
    background-color: #7bad7b;
  }
`
const StyledVideoIcon = styled(VideoCameraOutlined)`
  color: rgba(124, 124, 124) !important;
`

const CaseQueue = ({
  actions,
  caseQueue,
  caseQueueLoading,
  caseQueueStatusFilter,
  caseQueueCount,
  caseQueueCountLoading,
  sortDirection,
  physicianDetails
}) => {
  const [modalButtonLoading, setModalButtonLoading] = useState(false);
  const [caseForConsultationStart, setCaseForConsultationStart] = useState({});
  const [acceptAsLead, setAcceptAsLead] = useState(false)
  const [loadedQueue, setLoadedQueue] = useState(caseQueue)

  const [
    consultStartConfirmationModal,
    setConsultStartConfirmationModal,
  ] = useState(false);

  const isLeadPhysician = physicianDetails?.tags?.includes(PhysicianTagEnum.LEAD_PHYSICIAN)
  const sendSMSNotifications = physicianDetails?.preferences?.sendQueueSMSNotifications

  const addAnimationToRow = _id => {
    const row = document.querySelector(`[data-row-key='${_id}']`)
    row?.classList?.add('animate')
  }

  useEffect(() => {
    const queueUpdateIds = caseQueue.map(_case => _case._id)
    const newCaseIds = queueUpdateIds.filter(caseId => !loadedQueue.includes(caseId))
    newCaseIds.forEach(caseId => addAnimationToRow(caseId))
    setLoadedQueue(queueUpdateIds)
  // eslint-disable-next-line
  }, [caseQueue])

  const renderIcon = status => {
    switch(status) {
      case CaseStatusEnum.PENDING_SCHEDULE:
        return (
          <Tooltip title="Sync Case">
            <PhoneOutlined style={{ fontSize: '18px'}}/> / <StyledVideoIcon style={{ fontSize: '18px'}} />
          </Tooltip>
        )
      case CaseStatusEnum.RX_RENEWAL:
        return (
          <Tooltip title="Rx Renewal">
            <ReloadOutlined style={{ fontSize: '20px'}}/>
          </Tooltip>
        )
      case CaseStatusEnum.RX_CHANGE:
        return (
          <Tooltip title="Rx Change">
            <SwapOutlined style={{ fontSize: '20px'}}/>
          </Tooltip>
        )
        case CaseStatusEnum.PENDING_LABS:
          return (
            <Tooltip title="Labs">
              <ExperimentOutlined style={{ fontSize: '20px'}}/>
            </Tooltip>
          )
      case CaseStatusEnum.PENDING_PRESCRIPTION:
      case CaseStatusEnum.CASE_CREATED:
      default:
        return (
          <Tooltip title="New Async Case">
            <MedicineBoxOutlined style={{ fontSize: '20px'}}/>
          </Tooltip>
        )
    }
  }

  const columns = [
    {
      colSpan: 1,
      dataIndex: "status",
      key: "status_icon",
      width: 60,
      render: (val, i) => renderIcon(val),
    },
    {
      title: "Case No",
      colSpan: 1,
      dataIndex: "_id",
      key: "_id",
      width: 230,
      render: (val, i) => <span className={'new-case'}>{val}</span>,
    },
    {
      title: "Patient Name",
      dataIndex: "patient",
      key: "patientId",
      width: 180,
      render: (val, i) => <>{val?.name}</>,
    },
    {
      title: "Category Name",
      dataIndex: "category",
      key: "categoryId",
      width: 250,
      render: val => <>{val?.name}</>,
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      width: 140,
      render: val => <>{val}</>,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 230,
      render: val => val && (
        <div>
          <div>{dateTimeFormatter(val)}</div>
          <div>({moment(val).fromNow()})</div>
        </div>
      ),
      defaultSortOrder: 'ascend',
      sorter: () => {}
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    }
  ];

  const getCaseQueue = () => {
    const caseFilter = {
        page: 1, // strict limit of 50 per page, no pagination
        limit: 50, // strict limit of 50 per page, no pagination
        sortBy: 'createdAt',
        sortDirection: sortDirection === 'ascend' ? 1 : -1,
        status: caseQueueStatusFilter
    };

    actions.getCaseQueue(caseFilter, sendSMSNotifications);
  }

  useEffect(() => {
    getCaseQueue()
    isLeadPhysician && actions.getCaseQueueCount('internal')
      // eslint-disable-next-line
  }, [sortDirection, caseQueueStatusFilter, isLeadPhysician]);


  const handleConsultStartCancellation = (event) => {
    event.stopPropagation();

    setCaseForConsultationStart({});
    setAcceptAsLead(false)
    setConsultStartConfirmationModal(false);
    message.warn("Sorry, Please accept case to move to the case details");
  };

  const handleConsultationStartError = (messageString) => {
    setModalButtonLoading(false);
    setAcceptAsLead(false)
    setConsultStartConfirmationModal(false);
    message.error(messageString);
  };

  const handleConsultStartAccept = (event) => {
    event.stopPropagation();
    setModalButtonLoading(true);

    PhysicianApi
      .startConsultation({
        caseId: caseForConsultationStart._id,
        consultationStart: true,
        acceptAsLead
      })
      .then(({ data }) => {
        if (data?.success) {
          return pushRouteCaseDetails(caseForConsultationStart._id);
        } 

        const errorMessage = data?.message
          ? data.message
          : "Unable to Start Consult. Please try again later!"

        handleConsultationStartError(errorMessage)
      })
      .catch(({ response }) => {
        if (response?.data?.message) {
          const { message } = response.data
          if (message.includes('no longer available') && isLeadPhysician) {
            setAcceptAsLead(true)
            return setConsultStartConfirmationModal(true);
          } else {
            handleConsultationStartError(message);
            return getCaseQueue()
          }
        }
        handleConsultationStartError("Error occured while Starting Consultation. Please try again later!");
      })
      .finally(() => setModalButtonLoading(false))
  };

  const pushRouteCaseDetails = (caseId) => {
    history.push(
      `/dashboard/${userConstants.USER_PHYSICIAN}/caseview/${caseId}`,
      { from: 'case-queue' }
    );
  };

  const handleCaseListClick = (event, record) => {
    event.stopPropagation();
    setCaseForConsultationStart(record);
    setConsultStartConfirmationModal(true);
  };

  const CountDisplay = ({ isLoading }) => {
    return (
      <div>
        {isLoading ? <StyledSmallSpin /> : <>{`${caseQueueCount} total cases in global queue`}</>}
      </div>
    )
  }

  return (
    <div className="consulted-case">
      <div style={{ alignItems: 'center'}}>
        <span style={{ marginBottom: '10px', fontWeight: '500', color: 'rgb(124, 124, 124)'}}>
          <h1 style={{ marginBottom: '5px', lineHeight: '40px'}}>
            Case Queue 
            <Tooltip title="Watching for new cases">
              <StyledSpin/>
            </Tooltip>
          </h1>
          { isLeadPhysician && <CountDisplay isLoading={caseQueueCountLoading} count={caseQueueCount} /> }
        </span>
        <Select style={{ width: '200px', textTransform: 'capitalize', margin: '10px 0 20px 0' }} defaultValue='All' onChange={actions.setCaseQueueStatusFilter} disabled={caseQueueLoading}>
            <Select.Option value={null}>All</Select.Option>
            <Select.Option value={CaseStatusEnum.PENDING_PRESCRIPTION}><MedicineBoxOutlined style={{ marginRight: '4px'}}/> New Async Cases</Select.Option>
            <Select.Option value={CaseStatusEnum.RX_RENEWAL}><ReloadOutlined style={{ marginRight: '8px'}}/>Rx Renewals</Select.Option>
            <Select.Option value={CaseStatusEnum.RX_CHANGE}><SwapOutlined style={{ marginRight: '8px'}}/>Rx Changes</Select.Option>
            <Select.Option value={CaseStatusEnum.PENDING_SCHEDULE}><PhoneOutlined/> / <StyledVideoIcon style={{ fontSize: '12px', marginRight: '8px'}} /> Sync Cases</Select.Option>
            <Select.Option value={CaseStatusEnum.PENDING_LABS}><ExperimentOutlined style={{ marginRight: '8px'}}/>Labs</Select.Option>

          </Select>
      </div>
      <div className="consulted-case-no-of-patient-list-data" style={{ marginTop: 0 }}>
        <Spin
          size="large"
          spinning={caseQueueLoading}
          indicator={<LoadingOutlined spin style={{ color: '#7bad7b'}} />}
        >
          <div>
            <StyledTable
              className="case-queue-table"
              style={{ textTransform: 'capitalize'}}
              columns={columns}
              dataSource={caseQueue}
              rowKey="_id"
              scroll={{ x: 900 }}
              onChange={(_, __, sorter) => actions.setSortDirection(sorter.order)}
              pagination={{
                hideOnSinglePage: true,
                pageSize: 50
              }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    handleCaseListClick(event, record, rowIndex);
                  },
                };
              }}
            />
          </div>
        </Spin>
        <Modal
          title="Accept Case"
          okText="Ok"
          closable={false}
          maskClosable={false}
          centered
          visible={consultStartConfirmationModal}
          okButtonProps={{ loading: modalButtonLoading }}
          cancelButtonProps={{ loading: modalButtonLoading }}
          onOk={event => handleConsultStartAccept(event)}
          onCancel={event => handleConsultStartCancellation(event)}
        >
          <Text strong>Please confirm that you are accepting this case</Text>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    caseQueueLoading,
    caseQueue,
    caseQueueCount,
    caseQueueCountLoading,
    sortDirection,
    caseQueueStatusFilter
  } = state.caseQueue;
  return {
    caseQueueLoading,
    caseQueue,
    caseQueueCount,
    caseQueueCountLoading,
    physicianDetails: state.physicianDetails.physicianDetails,
    sortDirection,
    caseQueueStatusFilter
  };
};

const mapDispatchToProps = (dispatch) => {
  const { 
    getCaseQueue, 
    removeCaseFromQueue, 
    getCaseQueueCount, 
    setSortDirection,
    setCaseQueueStatusFilter
   } = caseQueue.actions;

  return {
    actions: bindActionCreators(
      {
        getCaseQueue,
        removeCaseFromQueue,
        getCaseQueueCount,
        setSortDirection,
        setCaseQueueStatusFilter
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseQueue);
