/* eslint-disable react-hooks/exhaustive-deps */
//import packages
import React, { useState, useEffect } from "react";
import { Row, Col, Typography, Collapse } from "antd";

// import components
import MIFAnswerView from "./MIFAnswerView";

const { Panel } = Collapse;
const { Title, Text } = Typography;

const MIFView = ({ title, answeredQuestions }) => {
  const [openedQuestionList, setOpenedQuestionList] = useState([]);
  const [questionAnswerSet, setQuestionAnswerSet] = useState([]);
  const formatMIF = () => {

    if (!Array.isArray(answeredQuestions)) {
      try {
        answeredQuestions = JSON.parse(answeredQuestions)
      } catch (e) {}
    }

    if (
      answeredQuestions &&
      Array.isArray(answeredQuestions) &&
      answeredQuestions.length
    ) {
      const openedQuestionIdList = answeredQuestions.map(
        (eachQuestionAnswer, index) => {
          let questionKey = null;
          if (eachQuestionAnswer && eachQuestionAnswer.q) {
            const { q } = eachQuestionAnswer;
            if (Array.isArray(q)) {
              questionKey = q[0]
                ? `${q[0]}-${index}`
                : `no-question-available-${index}`;
            } else {
              questionKey =
                q && typeof q === "string"
                  ? `${q}-${index}`
                  : `no-question-available-${index}`;
            }
          }
          return questionKey;
        }
      );
      const formattedMIF = answeredQuestions.map(
        (eachQuestionAnswer, index) => {
          const formattedQuestionAnswer = {
            question: "No Question Available",
            answer: "No Answer Available",
            nestedQuestionAnswers: [],
            priority: null,
          };
          if (
            eachQuestionAnswer &&
            Array.isArray(eachQuestionAnswer.q) &&
            Array.isArray(eachQuestionAnswer.a)
          ) {
            const { q, a, p, priority } = eachQuestionAnswer;
            const _priority = priority || p;

            q.forEach((eachQuestion, index) => {
              if (index === 0) {
                formattedQuestionAnswer.question = eachQuestion
                  ? eachQuestion
                  : "No Question Available";
                formattedQuestionAnswer.answer = a[index]
                  ? a[index]
                  : "No Answer Available";

                if (Array.isArray(_priority)) {
                    formattedQuestionAnswer.priority = _priority[index]
                }
              } else {
                const eachNestedQuestionAnswer = {
                  question: eachQuestion
                    ? eachQuestion
                    : "No Question Available",
                  answer: a[index] ? a[index] : "No Answer Available",
                  priority: Array.isArray(_priority) && _priority[index] ? _priority[index] : null
                };
                formattedQuestionAnswer.nestedQuestionAnswers.push({
                  ...eachNestedQuestionAnswer,
                });
              }
            });
          } else {
            if (
              eachQuestionAnswer &&
              eachQuestionAnswer.q &&
              typeof eachQuestionAnswer.q === "string"
            ) {
              const { q, a } = eachQuestionAnswer;
              formattedQuestionAnswer.question = q;
              formattedQuestionAnswer.answer =
                a && (Array.isArray(a) || typeof a === "string")
                  ? a
                  : "No Answer Available";
              formattedQuestionAnswer.nestedQuestionAnswers = [];
            }
          }
          return { ...formattedQuestionAnswer };
        }
      );
      setQuestionAnswerSet([...formattedMIF]);
      setOpenedQuestionList([...openedQuestionIdList]);
    } else {
      return false;
    }
  };

  useEffect(() => {
    formatMIF();
  }, [answeredQuestions]);
  return (
    <Row className="case-patient-details case-details-row-wrapper">
      <Title className="sub-content-color" level={4}>
        {title ? title : "Health Analysis"}
      </Title>
      <Col span={24}>
        {questionAnswerSet &&
        Array.isArray(questionAnswerSet) &&
        questionAnswerSet.length &&
        openedQuestionList.length ? (
          <Collapse
            bordered={false}
            defaultActiveKey={[...openedQuestionList]}
            expandIconPosition="right"
          >
            {questionAnswerSet.map((eachQuestionAnswer, parentIndex) => (
              <Panel
                header={
                  <Text strong>{`Question ${parentIndex + 1}:   ${
                    eachQuestionAnswer.question
                  }`}</Text>
                }
                key={`${eachQuestionAnswer.question}-${parentIndex}`}
              >
                <MIFAnswerView
                  answer={eachQuestionAnswer.answer}
                  answerIndex={parentIndex + 1}
                  priority={eachQuestionAnswer.priority}
                />
                {Array.isArray(eachQuestionAnswer.nestedQuestionAnswers) &&
                eachQuestionAnswer.nestedQuestionAnswers.length ? (
                  <Collapse
                    bordered={true}
                    defaultActiveKey={eachQuestionAnswer.nestedQuestionAnswers.map(
                      (eachQuestionAnswer, mappingIndex) =>
                        eachQuestionAnswer.question
                          ? `${eachQuestionAnswer.question}-${mappingIndex}`
                          : `no-question-available-${mappingIndex}`
                    )}
                    expandIconPosition="right"
                  >
                    {eachQuestionAnswer.nestedQuestionAnswers.map(
                      (eachNestedQuestionAnswer, nestedIndex) => (
                        <Panel
                          header={
                            <Text strong>{`Question ${parentIndex + 1}.${
                              nestedIndex + 1
                            }:   ${eachNestedQuestionAnswer.question}`}</Text>
                          }
                          key={`${eachNestedQuestionAnswer.question}-${nestedIndex}`}
                        >
                          <MIFAnswerView
                            answer={eachNestedQuestionAnswer.answer}
                            answerIndex={`${parentIndex + 1}.${
                              nestedIndex + 1
                            }`}
                            priority={eachNestedQuestionAnswer.priority}
                          />
                        </Panel>
                      )
                    )}
                  </Collapse>
                ) : (
                  <></>
                )}
              </Panel>
            ))}
          </Collapse>
        ) : (
          <div className="align-text-center">
            <Text strong>No Question Answers to List</Text>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default MIFView;
