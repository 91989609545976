//display the case allocated to physician

import React, { useState, useEffect, useContext } from "react";
import "./CaseList.css";
import { Table, Typography, Modal, Spin, message } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from 'moment';

import { history } from "../../helpers";
import { userConstants } from "../../constants";
import { LoadingOutlined } from "@ant-design/icons";
import physicianApi from "../../axios/physician/PhysicianApi";
import CaseSearchAndFilterComponent from "../../components/CaseSearchAndFilterComponent/CaseSearchAndFilterComponent";
import pendingPrescription from "../../redux/pendingPrescription";
import { sorters } from '../../utils/sorters';
import { TablePreferencesContext } from '../../context/TablePreferencesProvider';

const { Text } = Typography;
const TABLE_NAME = 'pending prescription';

const CaseList = (props) => {
  const [showModal, setShowModal] = useState(false)
  const [
    consultStartConfirmationModal,
    setConsultStartConfirmationModal,
  ] = useState(false);
  const [caseForConsultationStart, setCaseForConsultationStart] = useState({});
  const [modalButtonLoading, setModalButtonLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [caseListFilter, setCaseListFilter] = useState({});
  const { getTablePreferences, updateTablePreferences } = useContext(TablePreferencesContext);
  const { currentPage, tablePageSize, dateSortOrder } = getTablePreferences(TABLE_NAME);

  const {
    caseListLoading,
    pendingPrescriptionCaseList,
    pendingPrescriptionCaseCount,
    pendingPrescriptionCaseNotification,
    statesList,
    telemedicineCategoriesList,
    unassignedCaseNotification,
    actions,
  } = props;

  const fetchPendingPrescriptionCaseList = (page=1, limit=100, sortOrder=null) => {
    const tablePreferences = { currentPage: page, tablePageSize: limit, dateSortOrder: 'ascend' };

    if (sortOrder) {
      tablePreferences.dateSortOrder = sortOrder;
    }

    updateTablePreferences(TABLE_NAME, tablePreferences);

    const caseFilter = {
      index: page,
      'case-count': limit,
      sort: tablePreferences.dateSortOrder
    };

    const status = "pending prescription";
    caseFilter["status"] = status;
    if (searchValue !== null) {
      caseFilter["search-term"] = searchValue;
    }
    if (caseListFilter.teleMedicineCategories) {
      caseFilter["telemedicine-category-id"] =
        caseListFilter.teleMedicineCategories;
    }

    if (caseListFilter.status) {
       caseFilter['status'] = caseListFilter.status;
    }
    
    if (caseListFilter.state) {
      caseFilter["state-id"] = caseListFilter.state;
    }
    if (caseListFilter.caseid) {
      caseFilter["case-id"] = caseListFilter.caseid;
    }
    if (caseListFilter.patientid) {
      caseFilter["patient-id"] = caseListFilter.patientid;
    }
    if (caseListFilter.type) {
      caseFilter["type"] = caseListFilter.type;
    }
    if (caseListFilter.createddate) {
      caseFilter["created-date"] =
        caseListFilter.createddate &&
        moment(caseListFilter.createddate).format("YYYY-MM-DD");
    }
    actions.getPendingPrescriptionCaseList(caseFilter);
  };

  // eslint-disable-next-line
  useEffect(fetchPendingPrescriptionCaseList, [
    searchValue,
    caseListFilter,
    pendingPrescriptionCaseNotification,
    unassignedCaseNotification,
  ]);

  const columns = [
    {
      title: "Case No",
      dataIndex: "caseno",
      key: "caseno",
      sorter: (a, b) => sorters.alphanumeric(a.caseId, b.caseId)
    },
    {
      title: "Patient Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => sorters.alphanumeric(a.patientName, b.patientName)
    },
    {
      title: "Category Name",
      dataIndex: "categoryname",
      key: "categoryname",
      sorter: (a, b) => sorters.alphanumeric(a.categoryName, b.categoryName)
    },
    {
      title: "Created Date",
      dataIndex: "createddate",
      key: "createddate",
      defaultSortOrder: 'ascend',
      sortOrder: dateSortOrder,
      sorter: (a, b) => sorters.dates(new Date(a.createddate), new Date(b.createddate))
    },
    {
      title: "Status",
      dataIndex: "statusDisplayName",
      key: "status",
      sorter: (a, b) => sorters.alphanumeric(a.status, b.status)
    },
  ];

  const handleConsultStartCancellation = (event) => {
    event.stopPropagation();

    setCaseForConsultationStart({});
    setConsultStartConfirmationModal(false);
    message.warn("Sorry, Please start consultation to move to the case details");
  };

  const handleConsultationStartError = (messageString) => {
    setModalButtonLoading(false);
    setConsultStartConfirmationModal(false);
    message.error(messageString);
  };

  const handleConsultStartAccept = (event) => {
    event.stopPropagation();
    const data = {
      caseId: caseForConsultationStart.caseno,
      consultationStart: true,
    };
    setModalButtonLoading(true);
    physicianApi
      .startConsultation(data)
      .then((response) => {
        const { data } = response;
        if (data.success) {
          pushRouteCaseDetails(caseForConsultationStart.caseno);
        } else {
          if (data && data.message) {
            handleConsultationStartError(data.message);
          } else {
            handleConsultationStartError(
              "Unable to Start Consult. Please try again later!"
            );
          }
        }
      })
      .catch((error) => {
        if (error && error.response) {
          const { response } = error;
          if (response && response.data) {
            const { data } = response;
            if (data && data.message) {
              handleConsultationStartError(data.message);
            } else {
              handleConsultationStartError(
                "Error occured while Starting Consultation. Please try again later!"
              );
            }
          } else {
            handleConsultationStartError(
              "Error occured while Starting Consultation. Please try again later!"
            );
          }
        } else {
          handleConsultationStartError(
            "Error occured while Starting Consultation. Please try again later!"
          );
        }
      });
  };

  const handleOk = (filterData) => {
    setCaseListFilter(filterData);
    setShowModal(false);
  };

  const searchCase = (value) => {
    setSearchValue(value);
  };

  const handleCancel = () => {
    setCaseListFilter({});
    setShowModal(false);
  };

  const handleCancelCross = () => {
    setShowModal(false);
  };

  const pushRouteCaseDetails = (caseId) => {
    history.push(
      `/dashboard/${userConstants.USER_PHYSICIAN}/caseview/${caseId}`
    );
  };

  const handleCaseListClick = (event, record, rowIndex) => {
    event.stopPropagation();
    if (
      record.consultationType &&
      record.consultationType === "store and forward"
    ) {
      if (!record.consultationStart) {
        setCaseForConsultationStart(record);
        setConsultStartConfirmationModal(true);
      } else {
        pushRouteCaseDetails(record.caseno);
      }
    } else {
      pushRouteCaseDetails(record.caseno);
    }
  };

  return (
    <div className="waiting-room">
      <div className="waiting-room-case-list-part-heading">
        <h1>Pending Prescription</h1>
        <CaseSearchAndFilterComponent
          searchCase={searchCase}
          showModal={showModal}
          setShowModal={setShowModal}
          handleCancelCross={handleCancelCross}
          handleCancel={handleCancel}
          handleOk={handleOk}
          telemedicines={telemedicineCategoriesList}
          states={statesList}
          hideFields={['physician']}
        />
      </div>

      <div className="waiting-room-no-of-patient-list-data">
        <Spin
          spinning={caseListLoading}
          size="large"
          indicator={<LoadingOutlined spin />}
        >
          <div>
            <Table
              className="wating-room-table"
              columns={columns}
              dataSource={pendingPrescriptionCaseList}
              onChange={(pagination, filters, sorter) => fetchPendingPrescriptionCaseList(pagination.current, pagination.pageSize, sorter.order) }
              pagination={{
                total: pendingPrescriptionCaseCount,
                showTotal: (totalCase) => `Total ${totalCase} Cases`,
                current: currentPage,
                pageSize: tablePageSize,
                pageSizeOptions: ["5", "10", "20", "50", "100"],
                showSizeChanger: true,
                onShowSizeChange: (_, size) => fetchPendingPrescriptionCaseList(1, size),
                onChange: (page, size) => fetchPendingPrescriptionCaseList(page, size)
              }}
              scroll={{ x: 900 }}
              size="large"
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    handleCaseListClick(event, record, rowIndex);
                  },
                };
              }}
            />
          </div>
        </Spin>
        <Modal
          title="Please confirm Consultation Start!"
          okText="Start Consultation"
          closable={false}
          maskClosable={false}
          centered
          visible={consultStartConfirmationModal}
          okButtonProps={{
            loading: modalButtonLoading,
          }}
          cancelButtonProps={{
            loading: modalButtonLoading,
          }}
          onOk={(event) => {
            handleConsultStartAccept(event);
          }}
          onCancel={(event) => {
            handleConsultStartCancellation(event);
          }}
        >
          <Text strong>
            You haven't considered this case yet. Please confirm that you are
            starting the consultation for this case
          </Text>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    caseListLoading,
    pendingPrescriptionCaseList,
    pendingPrescriptionCaseCount,
  } = state.pendingPrescription;
  const {
    pendingPrescriptionCaseNotification,
    audioVideoCaseNotification,
    unassignedCaseNotification,
  } = state.notifications;
  const { scheduledCaseList, scheduledCaseListLoading } = state.scheduledCases;
  const { statesList, telemedicineCategoriesList } = state.entities;
  return {
    caseListLoading,
    pendingPrescriptionCaseList,
    pendingPrescriptionCaseCount,
    scheduledCaseList,
    scheduledCaseListLoading,
    pendingPrescriptionCaseNotification,
    audioVideoCaseNotification,
    statesList,
    telemedicineCategoriesList,
    unassignedCaseNotification,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { getPendingPrescriptionCaseList } = pendingPrescription.actions;
  return {
    actions: bindActionCreators(
      { getPendingPrescriptionCaseList },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseList);
