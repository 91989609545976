import React, { useState, useEffect, useContext } from "react";
import { Table, Modal, Spin, Typography, message } from "antd";
import { LoadingOutlined, BellOutlined } from "@ant-design/icons";

// components
import CaseSearchAndFilterComponent from "../../components/CaseSearchAndFilterComponent/CaseSearchAndFilterComponent";

// APIs
import PhysicianApi from "../../axios/physician/PhysicianApi";

// commons
import { userConstants } from "../../constants";
import { history } from "../../helpers";
import { sorters } from '../../utils/sorters';
import { dateTimeFormatter } from "../../utils/dateFormatter";

import { TablePreferencesContext } from '../../context/TablePreferencesProvider';

const { Text } = Typography;

const transformRxRequest = (data) => {
    if (!data) return {};

    const latestRxRenewalRequest = data?.rxRenewalRequests?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];

    const patientName = data.patientData?.length 
        ? `${data.patientData[0].firstName} ${data.patientData[0].lastName}` 
        : null;

    const categoryName = data.categoryData?.length
        ? data.categoryData[0].name
        : null;
    return {
        key: data._id,
        name: patientName,
        caseno: data._id,
        categoryname: categoryName,
        createddate: data.createdDate,
        status: data.status,
        statusDisplayName: data.status,
        consultationStart: data.consultationStart
            ? data.consultationStart
            : null,
        submittedAt: latestRxRenewalRequest?.createdAt,
        unreadChatNotification: data.readNotifications ? 1 : 0,
    };
}

const TABLE_NAME = 'rx renewals'

const InternalRxRequests = (props) => {
    const [consultStartConfirmationModal, setConsultStartConfirmationModal] = useState(false);
    const [caseForConsultationStart, setCaseForConsultationStart] = useState({});
    const [modalButtonLoading, setModalButtonLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState(null);
    const [filters, setFilters] = useState({});
    const [rxRenewalRequests, setRxRenewalRequests] = useState([]);
    const [recordCount, setRecordCount] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const { getTablePreferences, updateTablePreferences } = useContext(TablePreferencesContext);
    const { currentPage, tablePageSize } = getTablePreferences(TABLE_NAME);

    const fetchRxRenewals = (page = currentPage, size = tablePageSize) => {
        updateTablePreferences(TABLE_NAME, { currentPage: page, tablePageSize: size });

        const params = {
            status: "rx change",
            page: page, 
            count: size
        }

        setLoading(true);
        PhysicianApi.getRxRenewalRequests(params)
            .then(res => {
                const { data } = res;
                setRecordCount(data.data.count);
                setRxRenewalRequests(data.data.data?.map(transformRxRequest))
            })
            .finally(setLoading(false));
    };

    const columns = [
        {
            title: "Patient Name",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => sorters.alphanumeric(a.patientName, b.patientName)
        },
        {
            title: "Renewal Submitted",
            dataIndex: "submittedAt",
            key: "submittedAt",
            render: (_, caseDetails) => dateTimeFormatter(caseDetails.submittedAt),
            sorter: (a, b) => sorters.dates(new Date(a.submittedAt), new Date(b.submittedAt)),
        },
        {
            title: "Started",
            dataIndex: "consultationStart",
            key: "consultationStart",
            sorter: (a, b) => sorters.alphanumeric(a.consultationStart, b.consultationStart),
            render: (_, caseDetails) => caseDetails.consultationStart ? 'yes' : 'no'
        },
        {
            title: <BellOutlined />,
            dataIndex: 'unreadChatNotification',
            key: 'unreadChatNotification',
            sorter: (a, b) => sorters.alphanumeric(a.unreadChatNotification, b.unreadChatNotification),
        },
        {
            title: "Category",
            dataIndex: "categoryname",
            key: "categoryname",
            sorter: (a, b) => sorters.alphanumeric(a.categoryName, b.categoryName)
        },
        {
            title: "Case Created",
            dataIndex: "createddate",
            key: "createddate",
            render: (_, caseDetails) => dateTimeFormatter(caseDetails.createddate),
            sorter: (a, b) => sorters.dates(new Date(a.createddate), new Date(b.createddate))
        },
        {
            title: "Status",
            dataIndex: "statusDisplayName",
            key: "status",
            sorter: (a, b) => sorters.alphanumeric(a.status, b.status)
        },
    ];

    // eslint-disable-next-line
    useEffect(fetchRxRenewals, [searchValue, filters]);

    const handleConsultStartCancellation = (event) => {
        event.stopPropagation();
        setCaseForConsultationStart({});
        setConsultStartConfirmationModal(false);
        message.warn("Please start consultation to move to the follow-up case details");
    };

    const handleConsultationStartError = (messageString) => {
        setModalButtonLoading(false);
        setConsultStartConfirmationModal(false);
        message.error(messageString);
    };

    const handleConsultStartAccept = (event) => {
        event.stopPropagation();
        const data = {
            caseId: caseForConsultationStart.caseno,
            consultationStart: true,
        };
        setModalButtonLoading(true);
        PhysicianApi
            .startConsultation(data)
            .then((response) => {
                const { data } = response;
                if (data.success) {
                    pushRouteCaseDetails(caseForConsultationStart.caseno);
                } else {
                    if (data && data.message) {
                        handleConsultationStartError(data.message);
                    } else {
                        handleConsultationStartError("Unable to Start Consult. Please try again later!");
                    }
                }
            }).catch((error) => {
                if (error && error.response) {
                    const { response } = error;
                    if (response && response.data) {
                        const { data } = response;
                        if (data && data.message) {
                            handleConsultationStartError(data.message);
                        } else {
                            handleConsultationStartError("Error occured while Starting Consultation. Please try again later!");
                        }
                    } else {
                        handleConsultationStartError("Error occured while Starting Consultation. Please try again later!");
                    }
                } else {
                    handleConsultationStartError("Error occured while Starting Consultation. Please try again later!");
                }
            });
    };

    const pushRouteCaseDetails = (caseId) => {
        history.push(`/dashboard/${userConstants.USER_PHYSICIAN}/caseview/${caseId}`,
        { from: 'rx-change-requests' });
    };

    const handleCaseListClick = (event, record) => {
        event.stopPropagation();
        if (!record.consultationStart) {
            setCaseForConsultationStart(record);
            setConsultStartConfirmationModal(true);
        } else {
            pushRouteCaseDetails(record.caseno);
        }
    };

    const handleOk = (filterData) => {
        setFilters(filterData);
        setShowModal(false);
    };

    const handleCancel = () => {
        setFilters({});
        setShowModal(false);
    };

    const handleCancelCross = () => {
        setShowModal(false);
    };

    const handleSearch = (value) => {
        setSearchValue(value);
    }
    
    const handleShowModal = () => {
        setShowModal(true);
    }

    // TODO mv loading spinner to Antd table property
    return (
        <div className="consulted-case">
            <div className="waiting-room-case-list-part-heading">
                <h1>Rx Renewal Requests</h1>

                <CaseSearchAndFilterComponent
                    searchCase={handleSearch}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    handleCancelCross={handleCancelCross}
                    handleCancel={handleCancel}
                    handleOk={handleOk}
                    handleShowModal={handleShowModal}
                    telemedicines={null}
                    states={null}
                    hideFields={['physician']}
                />
            </div>
            <div className="consulted-case-no-of-patient-list-data">
                <Spin
                    size="large"
                    spinning={loading}
                    indicator={<LoadingOutlined spin />}
                >
                    <div>
                        <Table
                            className="wating-room-table"
                            columns={columns}
                            dataSource={rxRenewalRequests}
                            pagination={{
                                total: recordCount,
                                showTotal: (total) => `Total ${total} Rx Requests`,
                                pageSizeOptions: ["5", "10", "20", "50", "100"],
                                showSizeChanger: true,
                                current: currentPage,
                                pageSize: tablePageSize,
                                onShowSizeChange: (_, size) => fetchRxRenewals(1, size),
                                onChange: (page, size) => fetchRxRenewals(page, size),
                            }}
                            scroll={{ x: 900 }}
                            onRow={(record, rowIndex) => { 
                                return { onClick: event => handleCaseListClick(event, record, rowIndex) }; 
                            }}
                        />
                    </div>
                </Spin>
                <Modal
                    title="Please confirm Consultation Start"
                    okText="Start Consultation"
                    closable={false}
                    maskClosable={false}
                    centered
                    visible={consultStartConfirmationModal}
                    okButtonProps={{ loading: modalButtonLoading }}
                    cancelButtonProps={{ loading: modalButtonLoading }}
                    onOk={(event) => handleConsultStartAccept(event)}
                    onCancel={(event) => handleConsultStartCancellation(event)}
                >
                    <Text strong>
                        You haven't started this case for an Rx update yet. Please
                        confirm that you are starting the follow-up consult for this case
                    </Text>
                </Modal>
            </div>
        </div>
    );
};

export default InternalRxRequests;
