import React, { useState, useContext, useEffect } from "react";
import "../AdditionalInfoRequired/AdditionalInfoRequired.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Spin } from "antd";
import moment from 'moment-timezone'

import { history } from "../../helpers";
import { userConstants } from "../../constants";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import { LoadingOutlined } from "@ant-design/icons";

// import components
import CaseSearchAndFilterComponent from "../../components/CaseSearchAndFilterComponent/CaseSearchAndFilterComponent";
import CaseUpdateComparisonComponent from "../../components/CaseUpdateComparisonComponent/CaseUpdateComparisonComponent";
import otherPhysiciansCases from "../../redux/otherPhysiciansCases"
import { TablePreferencesContext } from '../../context/TablePreferencesProvider';

import socket from "../../axios/socket";

const TABLE_NAME = "Other Physicians' Cases";

const OtherPhysiciansCases = (props) => {
  const [showModal, setShowModal] = useState(false)
  const [caseDetailsComparisonId, setCaseDetailsComparisonId] = useState(null);
  const [viewCaseDetailsComparisonModal, setViewCaseDetailsComparisonModal] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [caseListFilter, setCaseListFilter] = useState({});
  const { getTablePreferences, updateTablePreferences } = useContext(TablePreferencesContext);
  const [ sortDirection, setSortDirection ] = useState(-1)
  const { currentPage, tablePageSize } = getTablePreferences(TABLE_NAME);

  const {
    actions,
    statesList,
    otherPhysiciansCaseList,
    telemedicineCategoriesList,
    otherPhysiciansCaseListLoading,
    otherPhysiciansCaseCount,
  } = props;

  const columns = [
    {
      title: "Case No",
      dataIndex: "_id",
      key: "_id",
    },
    {
      title: "Patient Name",
      dataIndex: "patient",
      key: "patientId",
      render: val => <>{val?.name}</>,
    },
    {
        title: "Assigned Physician",
        dataIndex: "physician",
        key: "physicianId",
        render: val => <>{val?.name}</>,
      },
    {
      title: "Category Name",
      dataIndex: "category",
      key: "categoryId",
      render: val => <>{val?.name}</>,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: val => val && <>{dateTimeFormatter(val)}</>,
      sorter: () => {}
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  const closeCaseDetailsComparisonModal = () => {
    setCaseDetailsComparisonId(null);
    setViewCaseDetailsComparisonModal(false);
  };

  const completeClearNotificationAndCaseListingUpdate = () => {
    setCaseDetailsComparisonId(null);
    setViewCaseDetailsComparisonModal(false);
  };

  const getOtherPhysiciansCases = () => {
    const caseFilter = {
        page: currentPage,
        limit: tablePageSize,
        sortBy: 'createdDate',
        sortDirection: sortDirection === 'ascend' ? -1 : 1,
    };

    searchValue && (caseFilter["search-term"] = searchValue)
    caseListFilter.status && (caseFilter.status = caseListFilter.status)
    caseListFilter.teleMedicineCategories && (caseFilter["telemedicine-category-id"] =caseListFilter.teleMedicineCategories)
    caseListFilter.type && (caseFilter["type"] = caseListFilter.type)
    caseListFilter.createddate && ( caseFilter["created-date"] = moment(caseListFilter.createddate).format("YYYY-MM-DD"))
    caseListFilter.assignedPhysician && (caseFilter.assignedPhysician = caseListFilter.assignedPhysician)
    actions.getOtherPhysiciansCases(caseFilter);
    actions.getOtherPhysiciansCaseCount({ ...caseFilter, count: true })
  };

  useEffect(() => {
    socket.connect()
    socket.on("OPCCaseAccepted", ({ caseId }) => actions.removeCaseFromOPC(caseId))
    return () => socket.removeAllListeners(["OPCCaseAccepted"])
    // eslint-disable-next-line
  }, [])

  // eslint-disable-next-line
  useEffect(getOtherPhysiciansCases, [
    searchValue,
    caseListFilter,
    currentPage,
    tablePageSize,
    sortDirection
  ]);

  const pushRouteCaseDetails = (caseId) => {
    history.push(
      `/dashboard/${userConstants.USER_PHYSICIAN}/caseview/${caseId}`,
      { from: 'other-physicians-cases' }
    );
  };

  const handleCaseListClick = (event, record) => {
    event.stopPropagation();
    pushRouteCaseDetails(record._id);
  };

  const handleIntialTableCount = () => {
    updateTablePreferences(TABLE_NAME, { currentPage: 1, tablePageSize: 100 })
  };

  const handleOk = (filterData) => {
    setCaseListFilter(filterData);
    setShowModal(false);
    handleIntialTableCount();
  };

  const searchCase = (value) => {
    setSearchValue(value);
    handleIntialTableCount();
  };

  const handleCancel = () => {
    setCaseListFilter({});
    setShowModal(false);
    handleIntialTableCount();
  };

  const handleCancelCross = () => {
    setShowModal(false);
  };

  const onChangePage = (page) => {
    updateTablePreferences(TABLE_NAME, { currentPage: page })
  };

  const onPageSizeChange = (_, size) => {
    updateTablePreferences(TABLE_NAME, { currentPage: 1, tablePageSize: size })
  };

  return (
    <div className="consulted-case">
      <div className="waiting-room-case-list-part-heading">
        <h1>Other Physicians' Cases</h1>
        <CaseSearchAndFilterComponent
          searchCase={searchCase}
          showModal={showModal}
          setShowModal={setShowModal}
          handleCancelCross={handleCancelCross}
          handleCancel={handleCancel}
          handleOk={handleOk}
          telemedicines={telemedicineCategoriesList}
          states={statesList}
          hideFields={['patientId', 'state', 'type']}
        />
      </div>
      <div className="consulted-case-no-of-patient-list-data">
        <Spin
          size="large"
          spinning={otherPhysiciansCaseListLoading}
          indicator={<LoadingOutlined spin />}
        >
          <div>
            <Table
              className="wating-room-table"
              columns={columns}
              onChange={(_, __, sorter) => setSortDirection(sorter.order)}
              pagination={{
                total: otherPhysiciansCaseCount,
                showTotal: () => <>Total {otherPhysiciansCaseCount || <Spin />} cases</>,
                current: currentPage,
                pageSize: tablePageSize,
                pageSizeOptions: ["5", "10", "20", "50", "100"],
                showSizeChanger: true,
                onChange: onChangePage,
                onShowSizeChange: onPageSizeChange,
              }}
              dataSource={otherPhysiciansCaseList}
              scroll={{ x: 900 }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    handleCaseListClick(event, record, rowIndex);
                  },
                };
              }}
            />
          </div>
        </Spin>
      </div>
      <CaseUpdateComparisonComponent
        modalVisible={viewCaseDetailsComparisonModal}
        caseId={caseDetailsComparisonId}
        setModalVisible={closeCaseDetailsComparisonModal}
        clearCaseNotificationCallback={
          completeClearNotificationAndCaseListingUpdate
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    otherPhysiciansCaseCountLoading,
    otherPhysiciansCaseListLoading,
    otherPhysiciansCaseCount,
    otherPhysiciansCaseList,
  } = state.otherPhysiciansCases;
  const { statesList, telemedicineCategoriesList } = state.entities;
  return {
    otherPhysiciansCaseCountLoading,
    otherPhysiciansCaseListLoading,
    otherPhysiciansCaseCount,
    otherPhysiciansCaseList,
    statesList,
    telemedicineCategoriesList,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { getOtherPhysiciansCases, getOtherPhysiciansCaseCount, removeCaseFromOPC } = otherPhysiciansCases.actions;
  return {
    actions: bindActionCreators(
      {
        getOtherPhysiciansCases,
        getOtherPhysiciansCaseCount,
        removeCaseFromOPC
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OtherPhysiciansCases);
