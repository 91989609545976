import moment from 'moment'

export const displayTimestamp = timestamp => {

    const now = moment(new Date())
    const messageTimestamp = moment(timestamp) 
    const elapsedTime = now.diff(messageTimestamp)
    
    // Within the hour
    if (elapsedTime < 1000 * 60 * 60) {
      return moment(timestamp).fromNow()
    // Within the same day
    } else if (moment(timestamp).startOf('day').diff(now.startOf('day')) === 0) {
      return moment(timestamp).format('h:mm a')
    // Within two days (yesterday)
    } else if (moment(timestamp).startOf('day').diff(now.subtract(1, 'day')) === 0) {
      return moment(timestamp).format('[Yesterday], h:mm a')
    // More than two days ago
    } else {
      return moment(timestamp).format('MMM D, YYYY h:mm a')
    }
  }
