import React, { useState, useEffect, useMemo } from 'react';
import { Button, Menu, Input, Popover, Tooltip, Typography } from 'antd';
import { ProfileOutlined, StarFilled } from '@ant-design/icons';
import PhysicianAPI from '../../axios/physician/PhysicianApi'
import Handlebars from 'handlebars';
import { htmlToText } from 'html-to-text';

const MessageTemplateButton = ({ caseDetails, physicianDetails, setText }) => {
  const [selected, setSelected] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);

  const templatesLookup = useMemo(() => templates.reduce((lookup, template) => {
    if (!(template._id in lookup)) {
      lookup[template._id] = template;
    }
    return lookup;
  }, {}), [templates])

  const sortFavorites = (templates) => {
    return [...templates].sort(a => a.favoritedBy ? -1 : 1);
  }

  const compileHandlebars = (message) => {
    const handlebarsTemplate = Handlebars.compile(message);

    return handlebarsTemplate({
      case: caseDetails,
      physician: physicianDetails,
      patient: caseDetails.patient
    })
  }

  const insertTemplate = () => {
    setText(text => {
      const { message } = templatesLookup[selected];
      return `${text}${compileHandlebars(message)}`
    });
  }

  const handleSearchTemplates = (event) => {
    const search = event.target.value.toLowerCase();
    setFilteredTemplates(
      sortFavorites(templates.filter(template => {
        const inTitle = template.title.toLowerCase().includes(search);
        const inMessage = template.message.toLowerCase().includes(search);
        return inTitle || inMessage;
      }))
    );
  }

  useEffect(() => {
    setFilteredTemplates(sortFavorites(templates));
  }, [templates])

  useEffect(() => {
    PhysicianAPI
      .getTemplates('message')
      .then((res) => setTemplates(res.data.templates))
  }, [])

  return (
    <Popover
      trigger='hover'
      placement='topLeft'
      content={
        <>
          <Menu
            mode='inline'
            onClick={e => setSelected(e.key)}
            selectedKeys={[selected]}
            style={{
              ...filteredTemplates.length ? {} : { display: 'flex', alignItems: 'center', justifyContent: 'center' },
              width: 400,
              height: 250,
            }}>
            {
              !!filteredTemplates.length
                ? filteredTemplates.map(template => (
                  <Menu.Item key={template._id}>
                    <Tooltip placement='right' trigger='hover' title={htmlToText(template.message)}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                          {template.favoritedBy && <StarFilled style={{ color: '#7BAD7E' }} />}
                          <Typography.Text style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{template.title}</Typography.Text>
                        </div>
                        {selected === template._id && <Button onClick={insertTemplate}>Select</Button>}
                      </div>
                    </Tooltip>
                  </Menu.Item>
                ))
                : <h5 style={{ color: 'rgba(193, 188, 188, 0.05)', textAlign: 'center' }}>No Templates to Display</h5>
            }
          </Menu>
          <Input placeholder='Find by title' onChange={handleSearchTemplates} />
        </>
      }
    >
      <Button
        type="secondary"
        className="chat-message-button"
        icon={<ProfileOutlined />}
      >Manage Templates</Button>
    </Popover>
  )
}

export default MessageTemplateButton;